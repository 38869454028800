import { createSlice } from '@reduxjs/toolkit';

const initialState = { isOpen: false, variant: '' };

const warningModalSlice = createSlice({
  name: 'warningModal',
  initialState,
  reducers: {
    setIsWaningModalOpen: (state, action) => {
      state.isOpen = action.payload.isOpen;
      state.variant = action.payload.variant;
    }
  },
});

export const { setIsWaningModalOpen } = warningModalSlice.actions
export default warningModalSlice.reducer
