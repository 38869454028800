import { combineReducers } from "redux";
import { configureStore } from '@reduxjs/toolkit'

import userReducer from './slices/userSlice';
import authModalReducer from './slices/authModalSlice';
import localeReducer from './slices/localeSlice';
import drawingsSlice from './slices/drawingsSlice';
import staffSlice from './slices/staffSlice';
import warningModalSlice from './slices/warningModalSlice';
import currencySlice from './slices/currencySlice';
import paginationSlice from './slices/paginationSlice';
import confirmationSlice from './slices/confirmationSlice';
import orderModalSlice from './slices/orderModalSlice';

const reducer = combineReducers({
  user: userReducer,
  authModal: authModalReducer,
  locale: localeReducer,
  drawings: drawingsSlice,
  staff: staffSlice,
  warningModal: warningModalSlice,
  currency: currencySlice,
  pagination: paginationSlice,
  confirmation: confirmationSlice,
  orderModal: orderModalSlice,
});

const store = configureStore({ reducer });

export default store;
