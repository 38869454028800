import React, { useCallback } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";
import { NavLink } from 'react-router-dom';
import i18n from '../i18n';
import axios from "axios";

import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';

import { ReactComponent as CartIcon } from '../assets/cart.svg';
import { ReactComponent as RubIcon } from '../assets/rub.svg';
import { ReactComponent as UsdIcon } from '../assets/usd.svg';
import { ReactComponent as SelectedIcon } from '../assets/selected.svg';

import { userLocalCart, userSelector, isUsdSelectedSelector } from "../store/selectors";
import { setUser } from '../store/slices/userSlice';
import { setLocale } from '../store/slices/localeSlice';
import { setCurrency, setIsUsdSelected } from '../store/slices/currencySlice';
import { setIsCartModalOpen } from '../store/slices/staffSlice';
import { setIsWaningModalOpen } from "../store/slices/warningModalSlice";

import { API_URLS, MAIN_COLOR } from '../consts';

import './styles.css';

export const Header = ({ onOpenAuthModal, setCartAlertMessage, setCurrentCartItems }) => {
  const user = useSelector(userSelector);
  const localCart = useSelector(userLocalCart);
  const isUsdSelected = useSelector(isUsdSelectedSelector);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onLogOut = useCallback(() => {
    dispatch(setUser({ user: null }));
    localStorage.removeItem('token');
  }, [dispatch]);

  const fetchCart = useCallback(async () => {
    const token = localStorage.getItem('token');
    const linkToFetch = user ? `${API_URLS.staff}/with-login/cart` : `${API_URLS.staff}/without-login/cart`;
    const paramsToFetch = user ? {
      token,
      id: user.userDrawingsId,
    } : {
      cartItems: localCart.join(', '),
    }

    try {
      const { data } = await axios.get(linkToFetch, {
        params: paramsToFetch,
      });

      if (data.successfully) {
        setCurrentCartItems(data.drawings);
      } else if (data.message === 'Errors.Auth.TokenExpired') {
        dispatch(setIsWaningModalOpen({ isOpen: true, variant: 'expired'}));
        dispatch(setUser({ user: null }));
        localStorage.removeItem('token');
      } else {
        setCartAlertMessage(t(data.message));
      }
    } catch (err) {
      dispatch(setIsWaningModalOpen({ isOpen: true, variant: 'general'}));
    }
  }, [dispatch, localCart, setCartAlertMessage, setCurrentCartItems, t, user]);

  const onCartClick = useCallback(async () => {
    await fetchCart();
    dispatch(setIsCartModalOpen({ isCartModalOpen: true }));
  }, [dispatch, fetchCart]);

  return <div
    className='position-fixed w-100 top-0 headerShadow'
    style={{ padding: '24px 72px', backgroundColor: MAIN_COLOR, zIndex: 99999 }}
  >
    <div className='d-flex justify-content-between w-100 '>
      <div className='d-flex'>
        <NavLink
          className='text-decoration-none'
          style={{color: 'white', marginRight: '12px'}}
          to='/'
        >
          Imdwg.com
        </NavLink>
        <NavLink
          className='text-decoration-none'
          style={{color: 'white', marginRight: '12px'}}
          to="/info"
        >
          {t('Header.Navigation.Info')}
        </NavLink>
        <NavLink
          className='text-decoration-none'
          style={{color: 'white', marginRight: '12px'}}
          to="/participation"
        >
          {t('Header.Navigation.Participation')}
        </NavLink>
        <NavLink
          className='text-decoration-none'
          style={{color: 'white', marginRight: '12px'}}
          to="/contacts"
        >
          {t('Header.Navigation.Contacts')}
        </NavLink>
        {user && user.isAdmin && (
          <NavLink
            className='text-decoration-none'
            style={{color: 'white', marginRight: '12px'}}
            to="/admin"
          >
            Админ панель
          </NavLink>
        )}
      </div>
      <div className='d-flex'>
        <Dropdown>
          <Dropdown.Toggle
            style={{backgroundColor: MAIN_COLOR, border: 'none'}}
          >
            {user ? user.email : t('Header.User.Profile')}
          </Dropdown.Toggle>
          {user ? (
            <Dropdown.Menu>
              <Dropdown.Item
                href="/profile"
              >{
                t('Header.User.Profile')}
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item onClick={onLogOut} href="">{t('Header.User.SignOut')}</Dropdown.Item>
            </Dropdown.Menu>
          ) : (
            <Dropdown.Menu>
              <Dropdown.Item href="" onClick={onOpenAuthModal}>{t('Header.User.SignIn')}</Dropdown.Item>
            </Dropdown.Menu>
          )}
        </Dropdown>
        <Dropdown>
          <Dropdown.Toggle style={{backgroundColor: MAIN_COLOR, border: 'none'}}>{t('Header.Language.ChangeLanguage')}</Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              href=""
              onClick={() => {
                i18n.changeLanguage('ru');
                localStorage.setItem('locale', 'ru');
                dispatch(setLocale({ locale: 'ru' }));
              }}
            >
              {t('Header.Language.Russian')}
            </Dropdown.Item>
            <Dropdown.Item
              href=""
              onClick={() => {
                i18n.changeLanguage('en');
                localStorage.setItem('locale', 'en');
                dispatch(setLocale({ locale: 'en' }));
              }}
            >
              {t('Header.Language.English')}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown>
          <Dropdown.Toggle style={{backgroundColor: MAIN_COLOR, border: 'none'}}>{t('Header.Currency.Title')}</Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              href=""
              onClick={() => {
                localStorage.setItem('currency', 'rub');
                dispatch(setCurrency({ currency: 'rub' }));
                dispatch(setIsUsdSelected({ isUsdSelected: false }));
              }}
            >
              <RubIcon style={{ marginRight: '1rem' }} />
              {t('Header.Currency.Rub')}
              {!isUsdSelected && <SelectedIcon style={{ marginLeft: '1rem' }} />}
            </Dropdown.Item>
            <Dropdown.Item
              href=""
              onClick={() => {
                localStorage.setItem('currency', 'usd');
                dispatch(setCurrency({ currency: 'usd' }));
                dispatch(setIsUsdSelected({ isUsdSelected: true }));
              }}
            >
              <UsdIcon style={{ marginRight: '1rem' }} />
              {t('Header.Currency.Usd')}
              {isUsdSelected && <SelectedIcon style={{ marginLeft: '1rem' }} />}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <Button variant="success" onClick={onCartClick}><CartIcon /></Button>
      </div>
    </div>
  </div>
}
