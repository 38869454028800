import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Spinner from "react-bootstrap/Spinner";

import { DrawingsList } from '../DrawingsList/DrawingsList';
import {
  searchDrawingsSelector,
} from "../../store/selectors";
import { usePagination } from "../../hooks/usePagination";

export const SearchDrawingsList = () => {
  const drawings = useSelector(searchDrawingsSelector);
  const { t } = useTranslation();

  const { currentPage, fetching } = usePagination(false, true);

  return (
    <>
      {currentPage === 1 && fetching ? (
        <div className="wrapperWithBorder">
          <Spinner/>
        </div>
      ) : (
        <div className="d-flex flex-column w-100">
          <h1 className="mb-5">{t('HomePage.SearchedDrawings')}</h1>
          <DrawingsList
            drawings={drawings}
            isCart={false}
            isPurchases={false}
            isFavorites={false}
            voidMessage={t('HomePage.NoDrawingsFound')}
            isPartialLoading={fetching}
          />
          <h4 className="mt-5 align-self-center">{t('HomePage.LetsSearch')}</h4>
        </div>
      )}
    </>
  )
}
