import React, { useCallback, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { localeSelector, userSelector } from "../../../store/selectors";
import { setIsOrderModalOpen } from "../../../store/slices/orderModalSlice";
import { setIsLoading, setLinks, setTransactionId } from "../../../store/slices/confirmationSlice";

import { API_URLS } from "../../../consts";

export const UKassa = ({ drawings, totalCount, setCurrentItems }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const locale = useSelector(localeSelector);

  const user = useSelector(userSelector);

  const [loading, setLoading] = useState(false);
  const [isWidgetError, setIsWidgetError] = useState(false);
  const [isAgreed, setIsAgreed] = useState(false);
  const [email, setEmail] = useState(user?.email);

  const onCheckboxClick = useCallback(() => {
    setIsAgreed(!isAgreed);
  }, [isAgreed]);

  const onEmailChange = useCallback((event) => {
    setEmail(event.target.value);
  }, []);

  const initWidget = useCallback(async () => {
    const ids = drawings?.map(drawing => drawing.id);

    try {
      const { data } = await axios.post(`${API_URLS.order}/yookassa/create-transaction`, {
        ids,
        email,
      });

      if (data.successfully) {
        const youKassaWidget = new window.YooMoneyCheckoutWidget({
          confirmation_token: data.confirmation_token,
          customization: {
            modal: true,
          },
          error_callback: function(error) {
            console.log('error', error);
          }
        });

        youKassaWidget.on('complete', async () => {
          try {
            const { data: createData } = await axios.post(`${API_URLS.order}/yookassa/create-order`, {
              ids,
              user,
              transactionId: data.payment_id,
            });

            if (createData.error) {
              dispatch(setIsOrderModalOpen({
                isOpen: true,
                variant: createData.error === 'Order.NotExist' && 'notExist' ||
                  createData.error === 'Order.PleaseComplete' && 'complete' ||
                  'common',
                transactionId: createData.transactionId
              }));
            }

            if (createData.successfully) {
              dispatch(setLinks({ links: createData.linksPrefixes }));
              dispatch(setTransactionId({ transactionId: createData.transactionId }));
              setCurrentItems([]);
              localStorage.setItem('linksPrefixes', createData.linksPrefixes.join('&&&'));
              localStorage.setItem('transactionId', createData.transactionId);
              localStorage.removeItem('localCart');
              navigate('/confirmation');
            }
          } catch (err) {
            dispatch(setIsOrderModalOpen({ isOpen: false, variant: 'tryAgain', transactionId: '' }));
          } finally {
            dispatch(setIsLoading({ isLoading: false }));
          }

          youKassaWidget.destroy();
        });

        youKassaWidget.on('fail', () => {
          dispatch(setIsOrderModalOpen({ isOpen: true, variant: 'tryAgain', transactionId: '' }));

          youKassaWidget.destroy();
        });

        youKassaWidget.on('modal_close', () => {
          dispatch(setIsOrderModalOpen({ isOpen: true, variant: 'tryAgain', transactionId: '' }));
        });

        youKassaWidget.render();
      }
    } catch (error) {
      dispatch(setIsOrderModalOpen({ isOpen: true, variant: 'tryAgain', transactionId: '' }));
      setIsWidgetError(true);
    } finally {
      setLoading(false);
    }
  }, [dispatch, drawings, navigate, setCurrentItems, totalCount?.rub, user, email]);

  const onWantToBuyClick = useCallback(async () => {
    if (!isWidgetError && window.YooMoneyCheckoutWidget) {
      setLoading(true);

      await initWidget();
    }
  }, [initWidget, isWidgetError]);

  if (isWidgetError || !window.YooMoneyCheckoutWidget) {
    return (
      <p>
        {t('Cart.YooKassaNotLoaded')}
      </p>
    )
  }

  return (
    <div>{
      loading ? (
        <Spinner />
      ) : (
        <div>
          <Form.Group>
            <Form.Label>E-mail</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder={locale === 'ru' ? 'Введите e-mail' : 'Enter e-mail'}
              value={email}
              onChange={onEmailChange}
              className="mb-2"
            />
          </Form.Group>
          <Form.Check
            required
            type="checkbox"
            label={t('Offer.Text')}
            value={isAgreed}
            onClick={onCheckboxClick}
            className="mb-2"
          />
          <Button
            className="w-100"
            variant="success"
            onClick={onWantToBuyClick}
            disabled={!isAgreed || !email}
          >
            {t('Cart.YooKassaWantToBuy')}
          </Button>
        </div>
      )
    }</div>
  )
}
