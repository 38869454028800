import React, { useCallback, useEffect, useMemo, useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";

import Button from "react-bootstrap/Button";

import { FiltersModal } from './FiltersModal';
import { ByRuEnBody } from './ChangeFiltersBodies/ByRuEnBody';
import { ByFormatBody } from './ChangeFiltersBodies/ByFormatBody';
import { ByDocumentationSection } from './ChangeFiltersBodies/ByDocumentationSection';
import { ByDependentBody } from './ChangeFiltersBodies/ByDependentBody';
import { CreateDependencies } from './ChangeFiltersBodies/CreateDependencies';
import { SearchForm } from '../SearchForm/SearchForm';

import { API_URLS, FILTERS_URLS } from '../../consts';
import {setIsWaningModalOpen} from "../../store/slices/warningModalSlice";

export const Filters = () => {
  const [filters, setFilters] = useState(null);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

  const dispatch = useDispatch();

  const fetchFilters = useCallback(async () => {
    const token = localStorage.getItem('token');

    try {
      const { data } = await axios.get(`${API_URLS.root}/filters`, {
        params: {
          token,
        }
      });

      if (data.successfully) {
        setFilters(data.filters);
      }
    } catch (error) {
      dispatch(setIsWaningModalOpen({ isOpen: true, variant: 'general'}));
    }
  }, [dispatch]);

  const closeFilersModal = useCallback(() => {
    setIsFilterModalOpen(false);
    fetchFilters();
  }, [fetchFilters]);

  const openFilersModal = useCallback(() => {
    setIsFilterModalOpen(true);
  }, [])

  const filtersStates = useMemo(() => (
    [
      {
        title: 'Добавить страну',
        component:
          <ByRuEnBody
            ruValues={filters?.byCountry?.ruvalues || ''}
            enValues={filters?.byCountry?.envalues || ''}
            id={filters?.byCountry?.id}
            onModalClose={closeFilersModal}
            URL={FILTERS_URLS.updateByCountry}
            titles={{
              first: 'Страны',
              second: 'Страна',
            }}
          />
      },
      {
        title: 'Добавить формат',
        component:
          <ByFormatBody
            values={filters?.byFormat?.values || ''}
            id={filters?.byFormat?.id}
            onModalClose={closeFilersModal}
          />
      },
      {
        title: 'Добавить объект',
        component:
          <ByRuEnBody
            ruValues={filters?.byBuilding?.ruvalues || ''}
            enValues={filters?.byBuilding?.envalues || ''}
            id={filters?.byBuilding?.id}
            onModalClose={closeFilersModal}
            URL={FILTERS_URLS.updateByBuilding}
            titles={{
              first: 'Объекты',
              second: 'Объект',
            }}
          />
      },
      {
        title: 'Добавить раздел документации',
        component:
          <ByDocumentationSection values={filters?.byDocumentationSection || []} onModalClose={closeFilersModal} />
      },
      {
        title: 'Добавить часть здания',
        component:
          <ByDependentBody
            onModalClose={closeFilersModal}
            values={filters?.byPartOfBuilding || []}
            byBuildingDependencies={filters?.byBuilding || []}
            title="Часть здания"
            tableName="building"
          />
      },
      {
        title: 'Добавить часть чертежа',
        component:
          <ByDependentBody
            onModalClose={closeFilersModal}
            values={filters?.byPartOfDrawing || []}
            byBuildingDependencies={filters?.byBuilding || []}
            title="Часть чертежа"
            tableName="drawing"
          />
      },
      {
        title: 'Добавить узел',
        component:
          <ByDependentBody
            onModalClose={closeFilersModal}
            values={filters?.byNode || []}
            byBuildingDependencies={filters?.byBuilding || []}
            title="Узел"
            tableName="node"
          />
      },
      {
        title: 'Создать зависимости',
        component:
          <CreateDependencies
            byDocumentationSection={filters?.byDocumentationSection || []}
            byPartOfBuilding={filters?.byPartOfBuilding || []}
            byPartOfDrawing={filters?.byPartOfDrawing || []}
            byNode={filters?.byNode || []}
            dependenciesCombinations={filters?.dependenciesCombinations || []}
          />
      },
    ]
  ),
    [
      filters?.byCountry?.ruvalues,
      filters?.byCountry?.envalues,
      filters?.byCountry?.id,
      filters?.byFormat?.values,
      filters?.byFormat?.id,
      filters?.byBuilding,
      filters?.byDocumentationSection,
      filters?.byPartOfBuilding,
      filters?.byPartOfDrawing,
      filters?.byNode,
      filters?.dependenciesCombinations,
      closeFilersModal
    ]
  );
  const [currentFilterState, setCurrentFilterState] = useState(filtersStates[0]);

  useEffect(() => {
    fetchFilters();
  }, [fetchFilters]);

  const onAddChangeCountryClick = useCallback(() => {
    setCurrentFilterState(filtersStates[0]);
    openFilersModal();
  }, [filtersStates, openFilersModal]);

  const onAddChangeFormatClick = useCallback(() => {
    setCurrentFilterState(filtersStates[1]);
    openFilersModal();
  }, [filtersStates, openFilersModal]);

  const onAddChangeBuildingClick = useCallback(() => {
    setCurrentFilterState(filtersStates[2]);
    openFilersModal();
  }, [filtersStates, openFilersModal]);

  const onAddChangeDocumentationSectionClick = useCallback(() => {
    setCurrentFilterState(filtersStates[3]);
    openFilersModal();
  }, [filtersStates, openFilersModal]);

  const onAddChangePartOfBuildingClick = useCallback(() => {
    setCurrentFilterState(filtersStates[4]);
    openFilersModal();
  }, [filtersStates, openFilersModal]);

  const onAddChangePartOfDrawingClick = useCallback(() => {
    setCurrentFilterState(filtersStates[5]);
    openFilersModal();
  }, [filtersStates, openFilersModal]);

  const onAddChangeNodeClick = useCallback(() => {
    setCurrentFilterState(filtersStates[6]);
    openFilersModal();
  }, [filtersStates, openFilersModal]);

  const onCreateDependenciesClick = useCallback(() => {
    setCurrentFilterState(filtersStates[7]);
    openFilersModal();
  }, [filtersStates, openFilersModal]);

  return (
    <div className="d-flex w-100 flex-column justify-content-center">
      <FiltersModal
        onHide={closeFilersModal}
        show={isFilterModalOpen}
        title={currentFilterState.title}
        component={currentFilterState.component}
      />
      <div className="d-flex w-100 justify-content-center mb-4">
        <Button className="m-2" onClick={onAddChangeDocumentationSectionClick}>Добавить/Изменить раздел документации</Button>
        <Button className="m-2" onClick={onAddChangeBuildingClick}>Добавить/Изменить объект</Button>
        <Button className="m-2" onClick={onAddChangeCountryClick}>Добавить/Изменить страну</Button>
        <Button className="m-2" onClick={onAddChangePartOfBuildingClick}>Добавить/Изменить часть объекта</Button>
        <Button className="m-2" onClick={onAddChangePartOfDrawingClick}>Добавить/Изменить часть чертежа</Button>
        <Button className="m-2" onClick={onAddChangeNodeClick}>Добавить/Изменить узел</Button>
        <Button className="m-2" onClick={onCreateDependenciesClick}>Создать зависимость</Button>
        <Button className="m-2" onClick={onAddChangeFormatClick}>Добавить/Изменить формат</Button>
      </div>
      <SearchForm filters={filters} />
    </div>
  )
}
