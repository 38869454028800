import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  locale: '',
};

const localeSlice = createSlice({
  name: 'locale',
  initialState,
  reducers: {
    setLocale: (state, action) => {
      state.locale = action.payload.locale;
    },
  },
});

export const { setLocale } = localeSlice.actions
export default localeSlice.reducer
