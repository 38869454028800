import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currency: 'usd',
  isUsdSelected: true,
};

const currencySlice = createSlice({
  name: 'currency',
  initialState,
  reducers: {
    setCurrency: (state, action) => {
      state.currency = action.payload.currency;
    },
    setIsUsdSelected: (state, action) => {
      state.isUsdSelected = action.payload.isUsdSelected;
    },
  },
});

export const { setCurrency, setIsUsdSelected } = currencySlice.actions
export default currencySlice.reducer
