import { useState, useMemo, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from "react-redux";

import { localeSelector, userSelector } from '../store/selectors';
import {
  setDrawings,
  setNewestDrawings,
  setAdminSearchDrawings,
  setAdminAlertMessage,
  setIsSearched,
  setSearchParams,
  setIsAdminSearchByName,
} from '../store/slices/drawingsSlice';
import { prepareDataByLanguageWithId, changeStringToDataSelect, getList } from '../utils/workWithDataSelect';
import { setToInitial } from "../store/slices/paginationSlice";

import { allValueRu, allValueEn } from '../consts';

export const useFilter = (filters) => {
  const locale = useSelector(localeSelector);
  const user = useSelector(userSelector);
  const dispatch = useDispatch();

  const [isByPartOfBuildingDisabled, setIsByPartOfBuildingDisabled] = useState(true);
  const [isByPartOfDrawingDisabled, setIsByPartOfDrawingDisabled] = useState(true);
  const [isByNodeDisabled, setIsByNodeDisabled] = useState(true);

  const byDocumentationSectionRu = useMemo(() =>
    filters?.byDocumentationSection?.length ?
      prepareDataByLanguageWithId(filters.byDocumentationSection, 'ruvalue') : [],
    [filters?.byDocumentationSection]);
  const byDocumentationSectionAllSectionRu = useMemo(() =>
      byDocumentationSectionRu?.filter(value => value.value === '1. Все разделы одного проекта'),
    [byDocumentationSectionRu]);
  const byDocumentationSectionEn = useMemo(() =>
      filters?.byDocumentationSection?.length ?
        prepareDataByLanguageWithId(filters.byDocumentationSection, 'envalue') : [],
    [filters?.byDocumentationSection]);
  const byDocumentationSectionAllSectionEn = useMemo(() =>
      byDocumentationSectionEn?.filter(value => value.value === '1. All sections of one project'),
    [byDocumentationSectionEn]);
  const [byDocumentationSection, setByDocumentationSection] = useState(null);
  const byBuildingRu = useMemo(() =>
    [
      allValueRu,
      ...changeStringToDataSelect(filters?.byBuilding?.ruvalues || ''),
    ],
    [filters?.byBuilding?.ruvalues]
  );
  const byBuildingEn = useMemo(() =>
    [
      allValueEn,
      ...changeStringToDataSelect(filters?.byBuilding?.envalues || ''),
    ],
    [filters?.byBuilding?.envalues]
  );
  const [byBuilding, setByBuilding] = useState(null);
  const byPartOfBuildingValues = useMemo(() =>
    getList(filters?.byPartOfBuilding || []), [filters?.byPartOfBuilding]
  );
  const [byPartOfBuilding, setByPartOfBuilding] = useState(null);
  const [actualPartOfBuilding, setActualByPartOfBuilding] = useState(null);
  const byPartOfDrawingValues = useMemo(() =>
    getList(filters?.byPartOfDrawing || []), [filters?.byPartOfDrawing]
  );
  const [byPartOfDrawing, setByPartOfDrawing] = useState(null);
  const [actualByPartOfDrawing, setActualByPartOfDrawing] = useState(null);
  const byNodeValues = useMemo(() =>
    getList(filters?.byNode || []), [filters?.byNode]
  );
  const [byNode, setByNode] = useState(null);
  const [actualByNode, setActualByNode] = useState(null);
  const byCountryRu = useMemo(() =>
    [
      allValueRu,
      ...changeStringToDataSelect(filters?.byCountry?.ruvalues || ''),
    ],
    [filters?.byCountry?.ruvalues]
  );
  const byCountryEn = useMemo(() =>
    [
      allValueEn,
      ...changeStringToDataSelect(filters?.byCountry?.envalues || ''),
    ],
    [filters?.byCountry?.envalues]
  );
  const [byCountry, setByCountry] = useState(null);
  const byFormat = useMemo(() =>
    [
      allValueEn,
      ...changeStringToDataSelect(filters?.byFormat?.id ? filters?.byFormat?.values : ''),
    ],
    [filters?.byFormat?.id, filters?.byFormat?.values]
  );
  const preparedCombinations = useMemo(() => {
    let preparedDependenciesCombinations = {};

    filters?.dependenciesCombinations?.forEach((dependency) => {
      preparedDependenciesCombinations = {
        ...preparedDependenciesCombinations,
        [dependency.documentationsectionid] : {
          byNodeListName: dependency.bynodelistname,
          byPartOfBuildingListName: dependency.bypartofbuildinglistname,
          byPartOfDrawingListName: dependency.bypartofdrawinglistname,
        }
      }
    });

    return preparedDependenciesCombinations;
  }, [filters?.dependenciesCombinations]);

  const [byDocumentationSectionValue, setByDocumentationSectionValue] = useState(null);
  const [byBuildingValue, setByBuildingValue] = useState(null);
  const [byPartOfBuildingValue, setByPartOfBuildingValue] = useState(null);
  const [byPartOfDrawingValue, setByPartOfDrawingValue] = useState(null);
  const [byNodeValue, setByNodeValue] = useState(null);
  const [byCountryValue, setByCountryValue] = useState(null);
  const [byFormatValue, setByFormatValue] = useState(allValueEn);

  const setDisabledValues = useCallback((isDisabled) => {
    setIsByPartOfBuildingDisabled(isDisabled);
    setIsByPartOfDrawingDisabled(isDisabled);
    setIsByNodeDisabled(isDisabled);
  }, []);

  const setAllValues = useCallback((allValue) => {
    setByPartOfBuildingValue(allValue);
    setByPartOfDrawingValue(allValue);
    setByNodeValue(allValue);
  }, []);

  const byBuildingValueChange = useCallback((event) => {
    setByBuildingValue(event);

    if (
      byDocumentationSectionValue?.value === '1. Все разделы одного проекта' ||
      byDocumentationSectionValue?.value === '1. All sections of one project'
    ) {
      return;
    }

    setDisabledValues(false);

    const allValue = locale === 'ru' ? allValueRu : allValueEn;

    setAllValues(allValue);

    const isAllSelected = event?.value === 'Все' || event?.value === 'All';

    const filteredByPartOfBuilding = isAllSelected ? actualPartOfBuilding :
      [
        allValue,
        ...actualPartOfBuilding?.filter((value) => value.dependencies?.includes(event?.value)),
      ] || null;
    const filteredByPartOfDrawing = isAllSelected ? actualPartOfBuilding :
      [
        allValue,
        ...actualByPartOfDrawing?.filter((value) => value.dependencies?.includes(event?.value)),
      ] || null;
    const filteredByNode = isAllSelected ? actualPartOfBuilding :
      [
        allValue,
        ...actualByNode?.filter((value) => value.dependencies?.includes(event?.value)),
      ] || null;

    setByPartOfBuilding(filteredByPartOfBuilding);
    setByPartOfDrawing(filteredByPartOfDrawing);
    setByNode(filteredByNode);
  }, [
    actualByNode,
    actualByPartOfDrawing,
    actualPartOfBuilding,
    byDocumentationSectionValue?.value,
    locale,
    setAllValues,
    setDisabledValues
  ]);

  const byDocumentationSectionValueChange = useCallback((event) => {
    setByDocumentationSectionValue(event);

    if (locale === 'ru') {
      setAllValues(allValueRu);
    } else {
      setAllValues(allValueEn);
    }

    if (event?.value === '1. Все разделы одного проекта' || event?.value === '1. All sections of one project') {
      setDisabledValues(true);
    } else {
      setDisabledValues(false);

      const dependencyValues = preparedCombinations[event?.id] || {};

      const currentByPartOfBuildingValues = byPartOfBuildingValues[dependencyValues.byPartOfBuildingListName]?.[locale];
      const currentByPartOfDrawingValues = byPartOfDrawingValues[dependencyValues.byPartOfDrawingListName]?.[locale];
      const currentByNodeValues = byNodeValues[dependencyValues.byNodeListName]?.[locale];

      setActualByPartOfBuilding(currentByPartOfBuildingValues);
      setActualByPartOfDrawing(currentByPartOfDrawingValues);
      setActualByNode(currentByNodeValues);

      setByPartOfBuilding(currentByPartOfBuildingValues);
      setByPartOfDrawing(currentByPartOfDrawingValues);
      setByNode(currentByNodeValues);
    }
  }, [
    byNodeValues,
    byPartOfBuildingValues,
    byPartOfDrawingValues,
    locale,
    preparedCombinations,
    setAllValues,
    setDisabledValues
  ]);

  useEffect(() => {
    if (locale === 'ru') {
      setByDocumentationSection(byDocumentationSectionRu);
      setByBuilding(byBuildingRu);
      setByCountry(byCountryRu);
      byDocumentationSectionValueChange(...byDocumentationSectionAllSectionRu);
    } else {
      setByDocumentationSection(byDocumentationSectionEn);
      setByBuilding(byBuildingEn);
      setByCountry(byCountryEn);
      byDocumentationSectionValueChange(...byDocumentationSectionAllSectionEn);
    }

    return () => {
      setByDocumentationSection(null);
      setByBuilding(null);
      setByCountry(null);
    }
  }, [
    byBuildingEn,
    byBuildingRu,
    byCountryEn,
    byCountryRu,
    byDocumentationSectionAllSectionEn,
    byDocumentationSectionAllSectionRu,
    byDocumentationSectionEn,
    byDocumentationSectionRu,
    byDocumentationSectionValueChange,
    locale
  ]);

  const byPartOfBuildingValueChange = useCallback((event) => {
    setByPartOfBuildingValue(event);

    if (event.value === 'Все' || event.value === 'All') {
      setIsByPartOfDrawingDisabled(false);
      setIsByNodeDisabled(false);
    } else {
      setIsByPartOfDrawingDisabled(true);
      setIsByNodeDisabled(true);
    }
  }, []);

  const byPartOfDrawingValueChange = useCallback((event) => {
    setByPartOfDrawingValue(event);

    if (event.value === 'Все' || event.value === 'All') {
      setIsByPartOfBuildingDisabled(false);
      setIsByNodeDisabled(false);
    } else {
      setIsByPartOfBuildingDisabled(true);
      setIsByNodeDisabled(true);
    }
  }, []);

  const byNodeValueChange = useCallback((event) => {
    setByNodeValue(event);

    if (event.value === 'Все' || event.value === 'All') {
      setIsByPartOfBuildingDisabled(false);
      setIsByPartOfDrawingDisabled(false);
    } else {
      setIsByPartOfBuildingDisabled(true);
      setIsByPartOfDrawingDisabled(true);
    }
  }, []);

  const getNewItems = useCallback(async () => {
    const currentByDocumentationSectionValue = byDocumentationSectionValue?.value || 'All';
    const currentByBuildingValue = byBuildingValue?.value || 'All';
    const currentByPartOfBuildingValue = byPartOfBuildingValue?.value || 'All';
    const currentByPartOfDrawingValue = byPartOfDrawingValue?.value || 'All';
    const currentByNodeValue = byNodeValue?.value || 'All';
    const currentByCountryValue = byCountryValue?.value || 'All';
    const currentByFormatValue = byFormatValue?.value || 'All';
    const token = localStorage.getItem('token');

    const searchParams = {
      currentByDocumentationSectionValue,
      currentByBuildingValue,
      currentByPartOfBuildingValue,
      currentByPartOfDrawingValue,
      currentByNodeValue,
      currentByCountryValue,
      currentByFormatValue,
      token,
    };

    dispatch(setSearchParams({ searchParams }));
    dispatch(setIsSearched({ isSearched: true }));
    dispatch(setToInitial());

    if (user?.isAdmin) {
      dispatch(setAdminAlertMessage({ adminAlertMessage: '' }));
      dispatch(setIsAdminSearchByName({ isAdminSearchByName: false }));
    }

    dispatch(setAdminSearchDrawings({ adminSearchDrawings: [] }));
    dispatch(setNewestDrawings({ newestDrawings: [] }));
    dispatch(setDrawings({ drawings: [] }));
  }, [
    byBuildingValue?.value,
    byCountryValue?.value,
    byDocumentationSectionValue?.value,
    byFormatValue?.value,
    byNodeValue?.value,
    byPartOfBuildingValue?.value,
    byPartOfDrawingValue?.value,
    dispatch,
    user,
  ]);

  return {
    byDocumentationSection,
    byBuilding,
    byPartOfBuilding,
    byPartOfDrawing,
    byNode,
    byCountry,
    byFormat,
    byDocumentationSectionValue,
    setByDocumentationSectionValue,
    byBuildingValue,
    setByBuildingValue,
    byPartOfBuildingValue,
    setByPartOfBuildingValue,
    byPartOfDrawingValue,
    setByPartOfDrawingValue,
    byNodeValue,
    setByNodeValue,
    byCountryValue,
    setByCountryValue,
    byFormatValue,
    setByFormatValue,
    isByPartOfBuildingDisabled,
    isByPartOfDrawingDisabled,
    isByNodeDisabled,
    byDocumentationSectionValueChange,
    byBuildingValueChange,
    byPartOfBuildingValueChange,
    byPartOfDrawingValueChange,
    byNodeValueChange,
    getNewItems,
  }
}