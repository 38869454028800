import { createSelector } from "@reduxjs/toolkit";

const selectSelf = (state) => state;

export const userSelector = createSelector(selectSelf, state => state.user.user);
export const isUserLoadingSelector = createSelector(selectSelf, state => state.user.isUserLoading);
export const userIsWantToLoginModalOpenSelector = createSelector(selectSelf, state => state.user.isWantToLoginModalOpen);
export const userIsFavoritesLoadingSelector = createSelector(selectSelf, state => state.staff.isFavoritesLoading);
export const userIsCartLoading = createSelector(selectSelf, state => state.staff.isCartLoading);
export const userIsNeedToFetchCartCart = createSelector(selectSelf, state => state.staff.isNeedToFetchCart);
export const userIsPurchasesLoading = createSelector(selectSelf, state => state.staff.isPurchasesLoading);
export const userFavoritesSelector = createSelector(selectSelf, state => state.staff.favorites);
export const userCart = createSelector(selectSelf, state => state.staff.cart);
export const userLocalCart = createSelector(selectSelf, state => state.staff.localCart);
export const userIsCartModalOpen = createSelector(selectSelf, state => state.staff.isCartModalOpen);
export const userPurchases = createSelector(selectSelf, state => state.staff.purchases);
export const isAuthModalOpenSelector = createSelector(selectSelf, state => state.authModal.isOpen);
export const localeSelector = createSelector(selectSelf, state => state.locale.locale);
export const searchDrawingsSelector = createSelector(selectSelf, state => state.drawings.drawings);
export const searchDrawingsParamsSelector = createSelector(selectSelf, state => state.drawings.searchParams);
export const searchNewestDrawingsSelector = createSelector(selectSelf, state => state.drawings.newestDrawings);
export const searchDrawingsIsLoadingSelector = createSelector(selectSelf, state => state.drawings.isLoading);
export const searchDrawingsIsSearchedSelector = createSelector(selectSelf, state => state.drawings.isSearched);
export const searchAdminSearchDrawingsSelector = createSelector(selectSelf, state => state.drawings.adminSearchDrawings);
export const isAdminSearchByNameSelector = createSelector(selectSelf, state => state.drawings.isAdminSearchByName);
export const adminFindStringSelector = createSelector(selectSelf, state => state.drawings.adminFindString);
export const searchAdminAlertMessageSelector = createSelector(selectSelf, state => state.drawings.adminAlertMessage);
export const searchIsPartialLoadingSelector = createSelector(selectSelf, state => state.drawings.isPartialLoading);
export const isWarningModalOpenSelector = createSelector(selectSelf, state => state.warningModal.isOpen);
export const warningModalVariantSelector = createSelector(selectSelf, state => state.warningModal.variant);
export const currencySelector = createSelector(selectSelf, state => state.currency.currency);
export const isUsdSelectedSelector = createSelector(selectSelf, state => state.currency.isUsdSelected);
export const paginationLimitSelector = createSelector(selectSelf, state => state.pagination.limit);
export const paginationCurrentPageSelector = createSelector(selectSelf, state => state.pagination.currentPage);
export const paginationTotalCountSelector = createSelector(selectSelf, state => state.pagination.totalCount);
export const paginationFetchingSelector = createSelector(selectSelf, state => state.pagination.fetching);
export const confirmationIsLoadingSelector = createSelector(selectSelf, state => state.confirmation.isLoading);
export const confirmationLinksSelector = createSelector(selectSelf, state => state.confirmation.links);
export const confirmationTransactionIdSelector = createSelector(selectSelf, state => state.confirmation.transactionId);
export const orderModalIsOpenSelector = createSelector(selectSelf, state => state.orderModal.isOpen);
export const orderModalVariantSelector = createSelector(selectSelf, state => state.orderModal.variant);
export const orderModalTransactionIdSelector = createSelector(selectSelf, state => state.orderModal.transactionId);
