import React from "react";

import Modal from 'react-bootstrap/Modal';

export const FiltersModal = ({ title, onHide, component, ...props}) => (
  <Modal
    {...props}
    aria-labelledby="contained-modal-title-vcenter"
    centered
    onHide={onHide}
    size="xl"
  >
    <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter">
        {title}
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {component}
    </Modal.Body>
  </Modal>
)