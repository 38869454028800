import React, { useCallback, useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

import { AddFiltersValues } from '../AddFiltersValues/AddFiltersValues';
import { setIsWaningModalOpen } from "../../../store/slices/warningModalSlice";
import { setUser } from "../../../store/slices/userSlice";

import { FILTERS_URLS } from '../../../consts';

export const ByFormatBody = ({ values, id }) => {
  const [currentValues, setCurrentValues] = useState(values.length ? values.split('&&&') : []);
  const [inputValue, setInputValue] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [alertVariant, setAlertVariant] = useState('');

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const updateInputValue = useCallback((event) => {
    setInputValue(event.target.value);
    setAlertMessage('');
  }, [setInputValue]);

  const onDeleteValue = useCallback((value) => {
    const filteredValues = currentValues.filter(currentValue => currentValue !== value);
    setCurrentValues(filteredValues);
    setAlertMessage('');
  }, [currentValues]);

  const onAddValue = useCallback(() => {
    if (inputValue) {
      setCurrentValues([...currentValues, inputValue]);
      setInputValue('');
      setAlertMessage('');
    }
  }, [currentValues, inputValue]);

  const onAddOrUpdateButtonClick = useCallback(async () => {
    const token = localStorage.getItem('token');

    setAlertMessage('');

    try {
      const result = await axios.post(FILTERS_URLS.updateByFormat, {
        id,
        values: currentValues.length ? currentValues.join('&&&') : '',
      }, {
        params: {
          token,
        }
      });

      if (result.data.successfully) {
        setAlertMessage('Сохранено!');
        setAlertVariant('success');
      } else {
        if (result.data.message === 'Errors.Auth.TokenExpired') {
          dispatch(setIsWaningModalOpen({ isOpen: true, variant: 'expired'}));
          dispatch(setUser({ user: null }));
          localStorage.removeItem('token');
        } else {
          setAlertMessage(t(result.data.message));
          setAlertVariant('danger');
        }
      }
    } catch (error) {
      dispatch(setIsWaningModalOpen({ isOpen: true, variant: 'general'}));
    }
  }, [currentValues, dispatch, id, t]);

  return (
    <div className="d-flex flex-column">
      {alertMessage && (
        <Alert variant={alertVariant}>
          {alertMessage}
        </Alert>
      )}
      <div className="d-flex justify-content-center">
        <AddFiltersValues
          values={currentValues}
          title="Форматы"
          onDeleteValue={onDeleteValue}
          inputValue={inputValue}
          updateInputValue={updateInputValue}
          onAddValue={onAddValue}
          placeholder="Формат"
        />
      </div>
      <Button variant="success" onClick={onAddOrUpdateButtonClick} className="mt-4">Сохранить</Button>
    </div>
  )
};
