import React, {useCallback, useEffect, useState} from "react";
import { useTranslation } from "react-i18next";

import Modal from 'react-bootstrap/Modal';

import { SignForm } from './SignForm';

export const AuthModal = ({ onCloseModal, ...props}) => {
  const [isSignIn, setIsSignIn] = useState(true);
  const { t } = useTranslation();

  const switchSignIn = useCallback(() => {
    setIsSignIn(!isSignIn);
  }, [isSignIn, setIsSignIn]);

  useEffect(() => {
    return () => {
      setIsSignIn(true);
    }
  }, []);

  const onHide = () => {
    setIsSignIn(true);
    onCloseModal();
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {isSignIn ? t('AuthModal.SignIn') : t('AuthModal.SignUp')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SignForm isSignIn={isSignIn} switchSignIn={switchSignIn} closeModal={onHide} />
      </Modal.Body>
    </Modal>
  );
}