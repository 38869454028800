import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useTranslation } from "react-i18next";

import { setCart, setIsNeedToFetchCart, setLocalCart } from "../store/slices/staffSlice";
import { setIsWaningModalOpen } from "../store/slices/warningModalSlice";
import { setUser } from "../store/slices/userSlice";

import { userCart, userLocalCart, userSelector } from "../store/selectors";

import { API_URLS } from "../consts";

export const useCart = (currentItems, setCurrentItems, setAlertMessage) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const localCart = useSelector(userLocalCart);
  const cart = useSelector(userCart);
  const user = useSelector(userSelector);

  const deleteItem = useCallback(async (cartToSend, newItems) => {
    const token = localStorage.getItem('token');

    try {
      const { data } = await axios.post(`${API_URLS.staff}/cart`, {
        id: user?.userDrawingsId,
        cart: cartToSend,
      }, {
        params: {
          token,
        }
      });

      if (data.successfully) {
        dispatch(setCart(
          {
            cart: cartToSend.length ? cartToSend.split(', ') : [],
            isCartLoading: false
          }
        ));
        dispatch(setIsNeedToFetchCart({ isNeedToFetchCart: true }));

        setCurrentItems(newItems);
      } else {
        if (data.message === 'Errors.Auth.TokenExpired') {
          dispatch(setIsWaningModalOpen({ isOpen: true, variant: 'expired'}));
          dispatch(setUser({ user: null }));
          localStorage.removeItem('token');
        } else {
          setAlertMessage(t(data.message));
        }
      }
    } catch (error) {
      dispatch(setIsWaningModalOpen({ isOpen: true, variant: 'general'}));
    }
  }, [dispatch, setAlertMessage, setCurrentItems, t, user?.userDrawingsId]);

  const onEmptyCartClick = useCallback(async () => {
    if (!user) {
      dispatch(setLocalCart({ localCart: [] }));
      setCurrentItems([]);
      localStorage.removeItem('localCart');
    } else {
      await deleteItem('', []);
    }
  }, [deleteItem, dispatch, setCurrentItems, user]);

  const onDeleteItemClick = useCallback(async (event, id) => {
    if (!user) {
      const dataToSave = localCart?.filter(cartItem => cartItem !== id)
      const localCartToSave = dataToSave.filter(data => data.length);
      const localCartToSaveString = localCartToSave.join(', ');
      const newItems = currentItems?.filter(cartItem => cartItem.id !== id);

      setCurrentItems(newItems);
      dispatch(setLocalCart({ localCart: localCartToSave }));
      localStorage.setItem('localCart', localCartToSaveString);
    } else {
      const dataToSend = cart?.filter(favorite => favorite !== id);
      const cartToSend = dataToSend.filter(data => data.length).join(', ');
      const newItems = currentItems.filter(item => item.id !== id);

      await deleteItem(cartToSend, newItems);
    }
  }, [cart, currentItems, deleteItem, dispatch, localCart, setCurrentItems, user]);

  return {
    onEmptyCartClick,
    onDeleteItemClick,
  }
}
