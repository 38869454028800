import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { SingleSelect } from '../SingleSelect/SingleSelect';

import { userSelector } from "../../store/selectors";
import { useFilter } from "../../hooks/useFilter";

import './styles.css';

export const SearchForm = ({ filters }) => {
  const {
    byDocumentationSection,
    byBuilding,
    byPartOfBuilding,
    byPartOfDrawing,
    byNode,
    byCountry,
    byFormat,
    byDocumentationSectionValue,
    byBuildingValue,
    byPartOfBuildingValue,
    byPartOfDrawingValue,
    byNodeValue,
    byCountryValue,
    setByCountryValue,
    byFormatValue,
    setByFormatValue,
    isByPartOfBuildingDisabled,
    isByPartOfDrawingDisabled,
    isByNodeDisabled,
    byDocumentationSectionValueChange,
    byBuildingValueChange,
    byPartOfBuildingValueChange,
    byPartOfDrawingValueChange,
    byNodeValueChange,
    getNewItems,
  } = useFilter(filters);

  const { t } = useTranslation();
  const user = useSelector(userSelector);

  return (
    <div className="d-flex justify-content-center">
      <Form className="w-100">
        <div className="d-flex m-0 justify-content-center">
          <Form.Group className="widthWrapper">
            <Form.Label className="m-0">{t('HomePage.SearchForm.DocumentationSection')}</Form.Label>
            <SingleSelect
              onChange={byDocumentationSectionValueChange}
              options={byDocumentationSection || []}
              value={byDocumentationSectionValue}
            />
          </Form.Group>
          <Form.Group className="widthWrapper">
            <Form.Label className="m-0">{t('HomePage.SearchForm.Building')}</Form.Label>
            <SingleSelect
              onChange={byBuildingValueChange}
              options={byBuilding || []}
              value={byBuildingValue}
            />
          </Form.Group>
          <Form.Group className="widthWrapper">
            <Form.Label className="m-0">{t('HomePage.SearchForm.Country')}</Form.Label>
            <SingleSelect
              onChange={(event) => setByCountryValue(event)}
              options={byCountry || []}
              value={byCountryValue}
            />
          </Form.Group>
        </div>
        <div className="d-flex align-items-end justify-content-center">
          <Form.Group className="widthWrapper">
            <Form.Label className="m-0">{t('HomePage.SearchForm.PartOfBuilding')}</Form.Label>
            <SingleSelect
              onChange={byPartOfBuildingValueChange}
              options={byPartOfBuilding || []}
              value={byPartOfBuildingValue}
              isDisabled={isByPartOfBuildingDisabled}
            />
          </Form.Group>
          <Form.Group className="widthWrapper">
            <Form.Label className="m-0">{t('HomePage.SearchForm.PartOfDrawing')}</Form.Label>
            <SingleSelect
              onChange={byPartOfDrawingValueChange}
              options={byPartOfDrawing || []}
              value={byPartOfDrawingValue}
              isDisabled={isByPartOfDrawingDisabled}
            />
          </Form.Group>
          <Form.Group className="widthWrapper">
            <Form.Label className="m-0">{t('HomePage.SearchForm.Node')}</Form.Label>
            <SingleSelect
              onChange={byNodeValueChange}
              options={byNode || []}
              value={byNodeValue}
              isDisabled={isByNodeDisabled}
            />
          </Form.Group>
        </div>
        <div className={`d-flex justify-content-center align-items-end ${user && user.isadmin ? 'pt-1' : 'pt-3'}`}>
          {user && user.isAdmin && (
            <Form.Group className="widthWrapper">
              <Form.Label className="m-0">{t('HomePage.SearchForm.Format')}</Form.Label>
              <SingleSelect
                onChange={(event) => setByFormatValue(event)}
                options={byFormat || []}
                value={byFormatValue}
              />
            </Form.Group>
          )}
          <Form.Group className="widthWrapper">
            <Button
              className="w-100 fw-bold"
              variant="success"
              onClick={getNewItems}
            >
              {t('HomePage.SearchForm.SearchButton')}
            </Button>
          </Form.Group>
        </div>
      </Form>
    </div>
  )
}
