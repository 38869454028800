import React from "react";

import { Page } from '../../hoc/Page';
import { ContentWrapper } from '../../hoc/ContentWrapper';
import { DrawingCard } from '../../components/DrawingCard/DrawingCard';

export const Drawing = () => {
  return (
    <Page>
      <ContentWrapper>
        <div className="d-flex justify-content-center flex-column mt-3">
          <DrawingCard drawing={{}} isFullView />
        </div>
      </ContentWrapper>
    </Page>
  )
};
