import React, {useCallback, useEffect, useRef, useState} from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { markdownToDraft, draftToMarkdown } from 'markdown-draft-js';
import { useDispatch } from "react-redux";

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from "react-bootstrap/Alert";
import ProgressBar from 'react-bootstrap/ProgressBar';

import { MultipleSelect } from '../MultipleSelect/MultipleSelect';

import {
  changeStringToDataSelect,
  getDataSelectByDocumentationSection,
  getListWithoutAll,
  getValue,
} from '../../utils/workWithDataSelect';
import { setIsWaningModalOpen } from "../../store/slices/warningModalSlice";
import { setUser } from "../../store/slices/userSlice";

import { API_URLS, allValueRu } from "../../consts";

import './styles.css';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

export const UpdateDrawing = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [alertMessage, setAlertMessage] = useState('');
  const [alertVariant, setAlertVariant] = useState('');
  const [ruLongDescription, setRuLongDescription] = useState('');
  const [enLongDescription, setEnLongDescription] = useState('');
  const [ruDescription, setRuDescription] = useState('');
  const [enDescription, setEnDescription] = useState('');
  const [costRub, setCostRub] = useState(0);
  const [costUsd, setCostUsd] = useState(0);
  const [byCountry, setByCountry] = useState([]);
  const [byBuilding, setByBuilding] = useState([]);
  const [byFormat, setByFormat] = useState([]);
  const [byDocumentationSection, setByDocumentationSection] = useState([]);
  const [byNode, setByNode] = useState([]);
  const [byPartOfBuilding, setByPartOfBuilding] = useState([]);
  const [byPartOfDrawing, setByPartOfDrawing] = useState([]);
  const [selectedByCountry, setSelectedByCountry] = useState(null);
  const [selectedByBuilding, setSelectedByBuilding] = useState(null);
  const [selectedByFormat, setSelectedByFormat] = useState(null);
  const [selectedByDocumentationSection, setSelectedByDocumentationSection] = useState(null);
  const [selectedByNode, setSelectedByNode] = useState(null);
  const [selectedByPartOfBuilding, setSelectedByPartOfBuilding] = useState(null);
  const [selectedByPartOfDrawing, setSelectedByPartOfDrawing] = useState(null);
  const [isValid, setIsValid] = useState(false);
  const [currentPicturesNames, setCurrentPicturesNames] = useState([]);
  const [currentDrawingFile, setCurrentDrawingFile] = useState('');
  const [ruEditorState, setRuEditorState] = useState(undefined);
  const [enEditorState, setEnEditorState] = useState(undefined);
  const [isFileLoading, setIsFileLoading] = useState(false);
  const [fileLoadingProgress, setFileLoadingProgress] = useState(0);

  const preparedCombinations = useRef({});
  const byPartOfBuildingValues = useRef([]);
  const byPartOfDrawingValues = useRef([]);
  const byNodeValues = useRef([]);

  const fetchDrawing = useCallback(async () => {
    const token = localStorage.getItem('token');

    setAlertMessage('');

    try {
      const { data } = await axios.get(`${API_URLS.drawings}/${id}`,  {
        params: {
          token,
        }
      });

      if (data.successfully) {
        const {
          ruLongDescription,
          enLongDescription,
          ruDescription,
          enDescription,
          costRub,
          costUsd,
          byCountry,
          byBuilding,
          byFormat,
          byDocumentationSection,
          byNode,
          byPartOfBuilding,
          byPartOfDrawing,
          isValid,
          picturesNames,
          fileName,
        } = data.drawing;

        const rawRuData = markdownToDraft(ruLongDescription);
        const ruContentState = convertFromRaw(rawRuData);
        const newRuEditorState = EditorState.createWithContent(ruContentState);

        const rawEnData = markdownToDraft(enLongDescription);
        const enContentState = convertFromRaw(rawEnData);
        const newEnEditorState = EditorState.createWithContent(enContentState);
        const selectedByDocumentationSection = byDocumentationSection ? changeStringToDataSelect(byDocumentationSection) : null;

        setRuLongDescription(ruLongDescription);
        setRuEditorState(newRuEditorState);
        setEnLongDescription(enLongDescription);
        setEnEditorState(newEnEditorState);
        setRuDescription(ruDescription);
        setEnDescription(enDescription);
        setCostRub(costRub);
        setCostUsd(costUsd);
        setSelectedByCountry(byCountry ? changeStringToDataSelect(byCountry) : null)
        setSelectedByBuilding(byBuilding ? changeStringToDataSelect(byBuilding) : null);
        setSelectedByFormat(byFormat ? changeStringToDataSelect(byFormat) : null);
        setSelectedByDocumentationSection(selectedByDocumentationSection);
        setSelectedByNode(byNode ? changeStringToDataSelect(byNode) : null);
        setSelectedByPartOfBuilding(byPartOfBuilding ? changeStringToDataSelect(byPartOfBuilding) : null);
        setSelectedByPartOfDrawing(byPartOfDrawing ? changeStringToDataSelect(byPartOfDrawing) : null);
        setIsValid(isValid);
        setCurrentPicturesNames(picturesNames ? picturesNames.split(', ') : []);
        setCurrentDrawingFile(fileName);

        if (selectedByDocumentationSection) {
          const { byPartOfBuilding, byPartOfDrawing, byNode} = getDataSelectByDocumentationSection(
              selectedByDocumentationSection,
              byPartOfBuildingValues.current,
              byPartOfDrawingValues.current,
              byNodeValues.current,
              preparedCombinations.current,
          )

          setByNode(byNode);
          setByPartOfDrawing(byPartOfDrawing);
          setByPartOfBuilding(byPartOfBuilding);
        }
      } else {
        if (data.message === 'Errors.Auth.TokenExpired') {
          dispatch(setIsWaningModalOpen({ isOpen: true, variant: 'expired'}));
          dispatch(setUser({ user: null }));
          localStorage.removeItem('token');
        } else {
          dispatch(setIsWaningModalOpen({ isOpen: true, variant: 'notExist'}));
        }
      }
    } catch (error) {
      dispatch(setIsWaningModalOpen({ isOpen: true, variant: 'general'}));
    }
  }, [dispatch, id]);

  const fetchFilters = useCallback(async () => {
    const token = localStorage.getItem('token');

    try {
      const { data } = await axios.get(`${API_URLS.root}/filters`, {
        params: {
          token,
        }
      });

      if (data.successfully) {
        const {
          filters: {
            byNode,
            byPartOfBuilding,
            byPartOfDrawing,
            dependenciesCombinations,
          },
          uniqValues: {
            byCountry,
            byBuilding,
            byFormat,
            byDocumentationSection
          }
        } = data;

        setByCountry([
          allValueRu,
          ...changeStringToDataSelect(byCountry || ''),
        ]);
        setByBuilding([
          allValueRu,
          ...changeStringToDataSelect(byBuilding || ''),
        ]);
        setByFormat([
          allValueRu,
          ...changeStringToDataSelect(byFormat || ''),
        ]);
        setByDocumentationSection([
          allValueRu,
          ...changeStringToDataSelect(byDocumentationSection || ''),
        ]);

        const byNodeFilterValues = getListWithoutAll(byNode || []);
        const byPartOfBuildingFilterValues = getListWithoutAll(byPartOfBuilding || []);
        const byPartOfDrawingFilterValues = getListWithoutAll(byPartOfDrawing || []);

        let preparedDependenciesCombinations = {};

        dependenciesCombinations?.forEach((dependency) => {
          preparedDependenciesCombinations = {
            ...preparedDependenciesCombinations,
            [dependency.documentationsectionname] : {
              byNodeListName: dependency.bynodelistname,
              byPartOfBuildingListName: dependency.bypartofbuildinglistname,
              byPartOfDrawingListName: dependency.bypartofdrawinglistname,
            }
          }
        });

        byPartOfBuildingValues.current = byPartOfBuildingFilterValues;
        byPartOfDrawingValues.current = byPartOfDrawingFilterValues;
        byNodeValues.current = byNodeFilterValues;
        preparedCombinations.current = preparedDependenciesCombinations;
      }  else {
        if (data.message === 'Errors.Auth.TokenExpired') {
          dispatch(setIsWaningModalOpen({ isOpen: true, variant: 'expired'}));
          dispatch(setUser({ user: null }));
          localStorage.removeItem('token');
        } else {
          setAlertMessage(t(data.message));
          setAlertVariant('danger');
        }
      }
    } catch (error) {
      dispatch(setIsWaningModalOpen({ isOpen: true, variant: 'general'}));
    }
  }, [dispatch, t]);

  const selectedByCountryChange = useCallback((event) => {
    setSelectedByCountry(event);
  }, []);

  const selectedByBuildingChange = useCallback((event) => {
    setSelectedByBuilding(event);
  }, []);

  const selectedByFormatChange = useCallback((event) => {
    setSelectedByFormat(event);
  }, []);

  const selectedByDocumentationSectionChange = useCallback((event) => {
    setSelectedByDocumentationSection(event);

    const { byPartOfBuilding, byPartOfDrawing, byNode} = getDataSelectByDocumentationSection(
        event,
        byPartOfBuildingValues.current,
        byPartOfDrawingValues.current,
        byNodeValues.current,
        preparedCombinations.current,
    )

    setByNode(byNode);
    setByPartOfDrawing(byPartOfDrawing);
    setByPartOfBuilding(byPartOfBuilding);
  }, []);

  const selectedByNodeChange = useCallback((event) => {
    setSelectedByNode(event);
  }, []);

  const selectedByPartOfBuildingChange = useCallback((event) => {
    setSelectedByPartOfBuilding(event);
  }, []);

  const selectedByPartOfDrawingChange = useCallback((event) => {
    setSelectedByPartOfDrawing(event);
  }, []);

  useEffect(() => {
    fetchFilters().then(fetchDrawing());
  }, [fetchFilters, fetchDrawing]);

  const onRuDescriptionChange = useCallback((event) => {
    setRuDescription(event.target.value);
  }, []);

  const onEnDescriptionChange = useCallback((event) => {
    setEnDescription(event.target.value);
  }, []);

  const onCostRubChange = useCallback((event) => {
    setCostRub(event.target.value);
  }, []);

  const onCostUsdChange = useCallback((event) => {
    setCostUsd(event.target.value);
  }, []);

  const onSaveClick = useCallback(async () => {
    const token = localStorage.getItem('token');

    const byDocumentationSectionValues =
      selectedByDocumentationSection ? getValue(selectedByDocumentationSection, byDocumentationSection) : '';
    const byBuildingValues =
      selectedByBuilding ? getValue(selectedByBuilding, byBuilding) : '';
    const byPartOfBuildingValues =
      selectedByPartOfBuilding ? getValue(selectedByPartOfBuilding, byPartOfBuilding) : '';
    const byPartOfDrawingValues =
      selectedByPartOfDrawing ? getValue(selectedByPartOfDrawing, byPartOfDrawing) : '';
    const byNodeValues =
      selectedByNode ? getValue(selectedByNode, byNode) : '';
    const byCountryValues =
      selectedByCountry ? getValue(selectedByCountry, byCountry) : '';
    const byFormatValues =
      selectedByFormat ? getValue(selectedByFormat, byFormat) : '';

    try {
      const { data } = await axios.post(`${API_URLS.drawings}/update`, {
        id,
        ruDescription,
        enDescription,
        ruLongDescription,
        enLongDescription,
        costRub: Number(costRub),
        costUsd: Number(costUsd),
        byDocumentationSection: byDocumentationSectionValues,
        byBuilding: byBuildingValues,
        byPartOfBuilding: byPartOfBuildingValues,
        byPartOfDrawing: byPartOfDrawingValues,
        byNode: byNodeValues,
        byCountry: byCountryValues,
        byFormat: byFormatValues,
        isValid,
      }, {
        params: {
          token,
        }
      });

      if (data.successfully) {
        setAlertMessage('Успешно обновлено!');
        setAlertVariant('success');
      }  else {
        if (data.message === 'Errors.Auth.TokenExpired') {
          dispatch(setIsWaningModalOpen({ isOpen: true, variant: 'expired'}));
          dispatch(setUser({ user: null }));
          localStorage.removeItem('token');
        } else {
          setAlertMessage(t(data.message));
          setAlertVariant('danger');
        }
      }
    } catch (error) {
      dispatch(setIsWaningModalOpen({ isOpen: true, variant: 'general'}));
    }
  }, [
    id,
    byBuilding,
    byCountry,
    byDocumentationSection,
    byFormat,
    byNode,
    byPartOfBuilding,
    byPartOfDrawing,
    costRub,
    costUsd,
    enDescription,
    enLongDescription,
    ruDescription,
    ruLongDescription,
    selectedByBuilding,
    selectedByCountry,
    selectedByDocumentationSection,
    selectedByFormat,
    selectedByNode,
    selectedByPartOfBuilding,
    selectedByPartOfDrawing,
    isValid,
    t,
    dispatch,
  ]);

  const onDeleteClick = useCallback(async () => {
    const token = localStorage.getItem('token');

    try {
      const result = await axios.delete(`${API_URLS.drawings}/delete`, {
        params: {
          token,
          id,
          picturesNames: currentPicturesNames,
        }
      });

      if (result.data.successfully) {
        navigate('/admin');
      } else {
        if (result.data.message === 'Errors.Auth.TokenExpired') {
          dispatch(setIsWaningModalOpen({ isOpen: true, variant: 'expired'}));
          dispatch(setUser({ user: null }));
          localStorage.removeItem('token');
        } else {
          setAlertMessage(t(result.data.message));
          setAlertVariant('danger');
        }
      }
    } catch (error) {
      dispatch(setIsWaningModalOpen({ isOpen: true, variant: 'general'}));
    }
  }, [currentPicturesNames, dispatch, id, navigate, t]);

  const onIsValidClick = useCallback(() => {
    setIsValid(!isValid);
  }, [isValid]);

  const onAddImages = useCallback(async (event) => {
    const images = [...event.target.files];
    const token = localStorage.getItem('token');

    let currentPicturesNamesAfterAdding = [...currentPicturesNames];

    for (const image of images) {
      const formData = new FormData();

      formData.append('file', image);

      try {
        const { data } = await axios.post(`${API_URLS.files}/image/${id}`, formData, {
          params: {
            token,
            picturesNames: [...currentPicturesNamesAfterAdding, image.name].join(', '),
          }
        });

        if (data.successfully) {
          setAlertMessage('Успешно обновлено!');
          setAlertVariant('success');
          currentPicturesNamesAfterAdding = [...currentPicturesNamesAfterAdding, image.name];
        } else {
          if (data.message === 'Errors.Auth.TokenExpired') {
            dispatch(setIsWaningModalOpen({ isOpen: true, variant: 'expired'}));
            dispatch(setUser({ user: null }));
            localStorage.removeItem('token');
          } else {
            setAlertMessage(t(data.message));
            setAlertVariant('danger');
          }
        }
      } catch (error) {
        dispatch(setIsWaningModalOpen({ isOpen: true, variant: 'general'}));
      }
    }

    setCurrentPicturesNames(currentPicturesNamesAfterAdding);
  }, [currentPicturesNames, dispatch, id, t]);

  const onDownloadPictureButtonClick = useCallback(async (name) => {
    const token = localStorage.getItem('token');

    const response = await fetch(`${API_URLS.files}/image/${name}?token=${token}`);

    if (response.status === 200) {
      const blob = await response.blob();
      const downloadURL = window.URL.createObjectURL(blob);
      const link = document.createElement('a');

      link.href = downloadURL;
      link.download = name;

      document.body.appendChild(link);

      link.click();
      link.remove();
    }
  }, []);

  const onDeleteImageClick = useCallback(async (event, name) => {
    const token = localStorage.getItem('token');

    try {
      const imagesAfterDeleting = currentPicturesNames.filter(picture => picture !== name);

      const { data } = await axios.delete(`${API_URLS.files}/image/${id}`, {
        params: {
          token,
          fileName: name,
          picturesNames: imagesAfterDeleting.join(', '),
        }
      });

      if (data.successfully) {
        setAlertMessage('Успешно удалено!');
        setAlertVariant('success');
        setCurrentPicturesNames(imagesAfterDeleting);
      } else {
        if (data.message === 'Errors.Auth.TokenExpired') {
          dispatch(setIsWaningModalOpen({ isOpen: true, variant: 'expired'}));
          dispatch(setUser({ user: null }));
          localStorage.removeItem('token');
        } else {
          setAlertMessage(t(data.message));
          setAlertVariant('danger');
        }
      }
    } catch (error) {
      dispatch(setIsWaningModalOpen({ isOpen: true, variant: 'general'}));
    }
  }, [currentPicturesNames, dispatch, id, t]);

  const onDownloadFileButtonClick = useCallback(async () => {
    const token = localStorage.getItem('token');

    const response = await fetch(`${API_URLS.files}/${id}/${currentDrawingFile}?token=${token}`);

    if (response.status === 200) {
      const blob = await response.blob();
      const downloadURL = window.URL.createObjectURL(blob);
      const link = document.createElement('a');

      link.href = downloadURL;
      link.download = currentDrawingFile;

      document.body.appendChild(link);

      link.click();
      link.remove();
    }
  }, [currentDrawingFile, id]);

  const onDeleteFileClick = useCallback(async () => {
    const token = localStorage.getItem('token');

    try {
      const { data } = await axios.delete(`${API_URLS.files}/${id}`, {
        params: {
          token,
          fileName: currentDrawingFile,
        }
      });

      if (data.successfully) {
        setAlertMessage('Успешно удалено!');
        setAlertVariant('success');
        setCurrentDrawingFile('');
      } else {
        if (data.message === 'Errors.Auth.TokenExpired') {
          dispatch(setIsWaningModalOpen({ isOpen: true, variant: 'expired'}));
          dispatch(setUser({ user: null }));
          localStorage.removeItem('token');
        } else {
          setAlertMessage(t(data.message));
          setAlertVariant('danger');
        }
      }
    } catch (error) {
      dispatch(setIsWaningModalOpen({ isOpen: true, variant: 'general'}));
    }
  }, [currentDrawingFile, dispatch, id, t]);

  const onAddFile = useCallback(async (event) => {
    const file = event.target.files[0];
    const token = localStorage.getItem('token');

    setFileLoadingProgress(0);
    setIsFileLoading(true);

    const formData = new FormData();

    formData.append('file', file);

    try {
      const { data } = await axios.post(`${API_URLS.files}/${id}`, formData, {
        params: {
          token,
          prevFileName: currentDrawingFile,
        },
        onUploadProgress: progressEvent => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);

          setFileLoadingProgress(percentCompleted);
        }
      });

      if (data.successfully) {
        setAlertMessage('Успешно обновлено!');
        setAlertVariant('success');
        setCurrentDrawingFile(file.name);
      } else {
        if (data.message === 'Errors.Auth.TokenExpired') {
          dispatch(setIsWaningModalOpen({ isOpen: true, variant: 'expired'}));
          dispatch(setUser({ user: null }));
          localStorage.removeItem('token');
        } else {
          setAlertMessage(t(data.message));
          setAlertVariant('danger');
        }
      }
    } catch (error) {
      dispatch(setIsWaningModalOpen({ isOpen: true, variant: 'general'}));
    } finally {
      setIsFileLoading(false);
      setFileLoadingProgress(0);
    }
  }, [currentDrawingFile, dispatch, id, t]);

  const onRuEditorStateChange = useCallback((newState) => {
    const content = newState.getCurrentContent();
    const rawObject = convertToRaw(content);
    const markdownString = draftToMarkdown(rawObject);

    setRuLongDescription(markdownString);
    setRuEditorState(newState);
  }, []);

  const onEnEditorStateChange = useCallback((newState) => {
    const content = newState.getCurrentContent();
    const rawObject = convertToRaw(content);
    const markdownString = draftToMarkdown(rawObject);

    setEnLongDescription(markdownString);
    setEnEditorState(newState);
  }, []);

  return (
    <div>
      {alertMessage && (
        <Alert variant={alertVariant}>
          {alertMessage}
        </Alert>
      )}
      <h1 className="mb-4">Создать/Изменить чертеж</h1>
      <Form className="d-flex flex-column">
        <div className="d-flex">
          <Form.Group className="w-50" style={{ marginRight: '1rem' }}>
            <Form.Label>Описание на русском</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Описание на русском"
              value={ruDescription}
              onChange={onRuDescriptionChange}
              className="mb-2"
            />
          </Form.Group>
          <Form.Group className="w-50" style={{ marginLeft: '1rem' }}>
            <Form.Label>Описание на английском</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Описание на английском"
              value={enDescription}
              onChange={onEnDescriptionChange}
              className="mb-2"
            />
          </Form.Group>
        </div>
        <div className="d-flex">
          <Form.Group className="w-50" style={{ marginRight: '1rem' }}>
            <Form.Label>Большое описание на русском</Form.Label>
            <Editor
              editorState={ruEditorState}
              wrapperClassName="draftWrapper"
              editorClassName="editorWrapper"
              onEditorStateChange={onRuEditorStateChange}
            />
          </Form.Group>
          <Form.Group className="w-50" style={{ marginLeft: '1rem' }}>
            <Form.Label>Большое описание на английском</Form.Label>
            <Editor
              editorState={enEditorState}
              wrapperClassName="draftWrapper"
              editorClassName="editorWrapper"
              onEditorStateChange={onEnEditorStateChange}
            />
          </Form.Group>
        </div>
        <div className="d-flex">
          <Form.Group className="w-50" style={{ marginRight: '1rem' }}>
            <Form.Label>Цена в рублях</Form.Label>
            <Form.Control
              type="text"
              placeholder="Цена в рублях"
              value={costRub}
              onChange={onCostRubChange}
              className="mb-2"
            />
          </Form.Group>
          <Form.Group className="w-50" style={{ marginLeft: '1rem' }}>
            <Form.Label>Цена в долларах</Form.Label>
            <Form.Control
              type="text"
              placeholder="Цена в долларах"
              value={costUsd}
              onChange={onCostUsdChange}
              className="mb-2"
            />
          </Form.Group>
        </div>
      </Form>
      <div className="d-flex">
        <div className="d-flex flex-column w-50" style={{ marginRight: '1rem' }}>
          <Form.Label>Страна</Form.Label>
          <MultipleSelect
            options={byCountry}
            onChange={selectedByCountryChange}
            selectedValue={selectedByCountry}
          />
        </div>
        <div className="d-flex flex-column w-50" style={{ marginLeft: '1rem' }}>
          <Form.Label>Объект</Form.Label>
          <MultipleSelect
            options={byBuilding}
            onChange={selectedByBuildingChange}
            selectedValue={selectedByBuilding}
          />
        </div>
      </div>
      <div className="d-flex">
        <div className="d-flex flex-column w-50" style={{ marginRight: '1rem' }}>
          <Form.Label>Раздел документации</Form.Label>
          <MultipleSelect
            options={byDocumentationSection}
            onChange={selectedByDocumentationSectionChange}
            selectedValue={selectedByDocumentationSection}
          />
        </div>
        <div className="d-flex flex-column w-50" style={{ marginLeft: '1rem' }}>
          <Form.Label>Узел</Form.Label>
          <MultipleSelect
            options={byNode}
            onChange={selectedByNodeChange}
            selectedValue={selectedByNode}
          />
        </div>
      </div>
      <div className="d-flex">
        <div className="d-flex flex-column w-50" style={{ marginRight: '1rem' }}>
          <Form.Label>Часть объекта</Form.Label>
          <MultipleSelect
            options={byPartOfBuilding}
            onChange={selectedByPartOfBuildingChange}
            selectedValue={selectedByPartOfBuilding}
          />
        </div>
        <div className="d-flex flex-column w-50" style={{ marginLeft: '1rem' }}>
          <Form.Label>Часть чертежа</Form.Label>
          <MultipleSelect
            options={byPartOfDrawing}
            onChange={selectedByPartOfDrawingChange}
            selectedValue={selectedByPartOfDrawing}
          />
        </div>
      </div>
      <div className="d-flex">
        <div className="d-flex flex-column w-50" style={{ marginRight: '1rem' }}>
          <Form.Label>Формат</Form.Label>
          <MultipleSelect
            options={byFormat}
            onChange={selectedByFormatChange}
            selectedValue={selectedByFormat}
          />
        </div>
        <div className="w-50" style={{ marginLeft: '1rem' }}></div>
      </div>
      <div className="d-flex justify-content-between">
        <Form.Group controlId="formImages" className="mb-3 w-50" style={{ marginRight: '1rem' }}>
          <Form.Label>Картинки</Form.Label>
            {currentPicturesNames.length !== 0 && (
              <div className="mb-3 d-flex flex-column">
                {currentPicturesNames.map(pictureName => (
                  <div key={pictureName} className='d-flex justify-content-between align-items-center mb-3'>
                    {pictureName}
                    <div>
                      <Button
                        onClick={() => onDownloadPictureButtonClick(pictureName)}
                        style={{marginRight: '1rem'}}
                      >
                        Скачать
                      </Button>
                      <Button variant="danger"
                              onClick={(event) => onDeleteImageClick(event, pictureName)}>Удалить</Button>
                    </div>
                  </div>
                ))}
              </div>
            )}
          <Form.Control type="file" multiple onChange={onAddImages}/>
        </Form.Group>
        <Form.Group controlId="formFile" className="mb-3 w-50" style={{ marginLeft: '1rem' }}>
          <Form.Label>Файл чертежа</Form.Label>
          {isFileLoading ? (
            <ProgressBar className="mb-3" variant="success" now={fileLoadingProgress} />
          ) : (
            currentDrawingFile ? (
              <div className="d-flex justify-content-between align-items-center mb-3">
                <span>{currentDrawingFile}</span>
                <div>
                  <Button
                    onClick={onDownloadFileButtonClick}
                    style={{ marginRight: '1rem' }}
                  >
                    Скачать
                  </Button>
                  <Button variant="danger" onClick={onDeleteFileClick}>Удалить</Button>
                </div>
              </div>
            ) : null
          )}
          <Form.Control type="file" onChange={onAddFile} />
        </Form.Group>
      </div>
      <Form.Group className="mb-3 d-flex">
        <Form.Check id="may-show-to-user" type="checkbox" checked={isValid} onChange={onIsValidClick} style={{ marginRight: '1rem' }} />
        <Form.Label htmlFor="may-show-to-user">Можно показывать пользователю?</Form.Label>
      </Form.Group>
      <div className="mt-5 d-flex justify-content-between">
        <Button
          className="mt-5 w-50"
          variant="success"
          onClick={onSaveClick}
          style={{ marginRight: '1rem' }}
        >
          Сохранить
        </Button>
        <Button
          className="mt-5 w-50"
          variant="danger"
          onClick={onDeleteClick}
          style={{ marginLeft: '1rem' }}
        >
          Удалить
        </Button>
      </div>
    </div>
  );
};
