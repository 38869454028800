export const MAIN_COLOR = '#76A28F';

export const API_URLS = {
  root: 'http://api.imdwg.com',
  auth: 'http://api.imdwg.com/auth',
  files: 'http://api.imdwg.com/files',
  publicFiles: 'http://api.imdwg.com/public',
  admin: 'http://api.imdwg.com/admin',
  drawings: 'http://api.imdwg.com/drawings',
  staff: 'http://api.imdwg.com/staff',
  order: 'http://api.imdwg.com/order',
};

export const FILTERS_URLS = {
  updateByCountry: `${API_URLS.admin}/update/by-country`,
  updateByFormat: `${API_URLS.admin}/update/by-format`,
  updateByBuilding: `${API_URLS.admin}/update/by-building`,
  updateByDocumentationSection: `${API_URLS.admin}/update/by-documentation-section`,
  deleteByDocumentationSection: `${API_URLS.admin}/delete/by-documentation-section`,
  updateByDependent: `${API_URLS.admin}/update/by-dependent`,
  deleteByDependent: `${API_URLS.admin}/delete/by-dependent`,
  updateDependencies: `${API_URLS.admin}/update/dependencies`,
  deleteDependencies: `${API_URLS.admin}/delete/dependencies`,
};

export const EMAIL_REGEX = new RegExp('^(([^<>()[\\]\\\\.,;:\\s@"]+(\\.[^<>()[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$');

export const PASSWORD_REGEX = new RegExp('^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\\s).{8,15}$');

export const allValueRu = {
  value: 'Все',
  label: 'Все',
};

export const allValueEn = {
  value: 'All',
  label: 'All',
};

export const supportEmail = 'ilovedwg@gmail.com';
