import React, { useState, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";

import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Spinner from "react-bootstrap/Spinner";

import {
  userSelector,
  searchAdminSearchDrawingsSelector,
  searchAdminAlertMessageSelector,
  searchDrawingsIsSearchedSelector,
  isUserLoadingSelector,
} from "../../store/selectors";
import { Page } from '../../hoc/Page';
import { AdminSearchByName } from "../../components/AdminSearchByName/AdminSearchByName";
import { ContentWrapper } from '../../hoc/ContentWrapper';
import { Filters } from '../../components/AdminPanel/Filters';
import { DrawingsList } from '../../components/DrawingsList/DrawingsList';
import { setIsWaningModalOpen } from "../../store/slices/warningModalSlice";
import { setUser } from "../../store/slices/userSlice";
import { setIsSearched } from "../../store/slices/drawingsSlice";
import { usePagination } from "../../hooks/usePagination";

import { API_URLS } from "../../consts";

export const Admin = () => {
  const user = useSelector(userSelector);
  const isUserLoading = useSelector(isUserLoadingSelector);
  const adminDrawings = useSelector(searchAdminSearchDrawingsSelector);
  const adminAlert = useSelector(searchAdminAlertMessageSelector);
  const isSearched = useSelector(searchDrawingsIsSearchedSelector);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { fetching, currentPage } = usePagination(false, false, true);

  const [alertMessage, setAlertMessage] = useState('');

  const onCreateDrawingButtonClick = useCallback(async () => {
    const token = localStorage.getItem('token');

    try {
      const { data } = await axios.post(`${API_URLS.drawings}/create`, {}, {
        params: {
          token,
        }
      });

      if (data.successfully) {
        navigate(`/admin/${data.id}`);
      } else {
        if (data.message === 'Errors.Auth.TokenExpired') {
          dispatch(setIsWaningModalOpen({ isOpen: true, variant: 'expired'}));
          dispatch(setUser({ user: null }));
          localStorage.removeItem('token');
        } else {
          setAlertMessage(t(data.message));
        }
      }
    } catch (error) {
      dispatch(setIsWaningModalOpen({ isOpen: true, variant: 'general'}));
    }
  }, [dispatch, navigate, t]);

  useEffect(() => {
    if (!user?.isAdmin && !isUserLoading) {
      dispatch(setIsWaningModalOpen({ isOpen: true, variant: 'admin'}));
    } else {
      dispatch(setIsWaningModalOpen({ isOpen: false, variant: ''}));
    }
  }, [dispatch, isUserLoading, user?.isAdmin]);

  useEffect(() => {
    return () => {
      dispatch(setIsSearched({ isSearched: false }));
    }
  }, []);

  return (
    <Page>
      <ContentWrapper>
        {user && user.isAdmin ? (
          <>
            {(alertMessage || adminAlert) && (
              <Alert variant='danger'>
                {alertMessage || adminAlert}
              </Alert>
            )}
            <Filters />
            <Button className="mb-5" onClick={onCreateDrawingButtonClick}>Создать чертеж</Button>
            <AdminSearchByName />
            {isSearched && (
              currentPage === 1 && fetching ? (
                <div className="wrapperWithBorder">
                  <Spinner/>
                </div>
              ) : (
                <DrawingsList drawings={adminDrawings} isAdmin />
              )
            )}
          </>
        ) : (
          <div>Not Admin</div>
        )}
      </ContentWrapper>
    </Page>
  )
}
