import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';

import noImage from '../../../assets/noImage.png';
import { localeSelector, currencySelector } from "../../../store/selectors";

import { API_URLS } from "../../../consts";

import '../styles.css';

export const CartItem = ({ cartItem, onDeleteCartItem }) => {
  const locale = useSelector(localeSelector);
  const currency = useSelector(currencySelector);
  const { t } = useTranslation();

  return (
    <div className="d-flex align-items-center justify-content-between mb-3" key={cartItem.id}>
      <Image
        className="imageCartModalBody"
        src={
          cartItem.picturesNames.length ?
          `${API_URLS.publicFiles}/${cartItem.picturesNames.split(', ')[0]}` :
          noImage
        }
        onError={({ currentTarget }) => {
          currentTarget.onerror = null;
          currentTarget.src=noImage;
        }}
      />
      <span className="w-50">{locale === 'ru' ? cartItem.ruDescription : cartItem.enDescription}</span>
      <div className="d-flex align-items-center justify-content-between w-25">
        <span className="fw-bold">{currency === 'rub' ? `${cartItem.costRub} ₽` : `${cartItem.costUsd} $`}</span>
        <Button
          variant="danger"
          onClick={(event) => onDeleteCartItem(event, cartItem.id)}
        >
          {t('CartModal.Delete')}
        </Button>
      </div>
    </div>
  );
};
