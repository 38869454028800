import i18n from "i18next";
import {
  initReactI18next
} from "react-i18next";
import {
  DateTime
} from 'luxon';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

i18n
  .use(Backend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: "en",
    react: {
      useSuspense: false
    },
    detection: {
      order: ['path', 'htmlTag']
    },
    interpolation: {
      format: (value, format, lng) => { // legacy usage
        if (value instanceof Date) {
          return DateTime.fromJSDate(value).setLocale(lng).toLocaleString(DateTime[format])
        }
        return value;
      }
    }
  });

i18n.changeLanguage(navigator.language);
i18n.on('languageChanged', (lng) => {
    document.documentElement.setAttribute('lang', lng);
});

export default i18n;
