import React, {useCallback, useEffect, useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import Button from 'react-bootstrap/Button';
import Carousel from 'react-bootstrap/Carousel';
import Image from 'react-bootstrap/Image';
import Markdown from "markdown-to-jsx";

import { ReactComponent as Heart } from '../../assets/heart.svg';
import { ReactComponent as FilledHeart } from '../../assets/filled-heart.svg';
import noImage from '../../assets/noImage.png';

import {
  localeSelector,
  userLocalCart,
  userSelector,
  userFavoritesSelector,
  userCart,
  currencySelector,
  userPurchases,
} from "../../store/selectors";
import { setIsModalOpen } from "../../store/slices/authModalSlice";
import { setFavorites, setCart, setLocalCart, setIsNeedToFetchCart } from "../../store/slices/staffSlice";
import { setIsWantToLoginModalOpen, setUser } from "../../store/slices/userSlice";
import { setIsWaningModalOpen } from "../../store/slices/warningModalSlice";

import { API_URLS } from '../../consts';

import './styles.css';

export const DrawingCard = (
  {
    drawing,
    isPurchases = false,
    isCart = false,
    isAdmin = false,
    isFullView = false,
    onDeleteItemClick,
  }
) => {
  const [isInFavorite, setIsInFavorite] = useState(false);
  const [isInCart, setIsInCart] = useState(false);
  const [index, setIndex] = useState(0);
  const [currentData, setCurrentData] = useState(null);
  const [currentImages, setCurrentImages] = useState([]);
  const [isInPurchases, setIsInPurchases] = useState(false);

  const locale = useSelector(localeSelector);
  const user = useSelector(userSelector);
  const favorites = useSelector(userFavoritesSelector);
  const purchases = useSelector(userPurchases);
  const cart = useSelector(userCart);
  const localCart = useSelector(userLocalCart);
  const currency = useSelector(currencySelector);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id: idToFetch } = useParams();

  const {
    id,
    ruDescription,
    enDescription,
    costRub,
    costUsd,
    picturesNames,
    fileName,
    isValid,
  } = drawing;

  const picturesURLs = picturesNames?.split(', ');

  const handleSelect = useCallback((selectedIndex, e) => {
    e.preventDefault();
    e.stopPropagation();

    setIndex(selectedIndex);
  }, []);

  const onFavoriteClickWithUser = useCallback(async (event) => {
    event.preventDefault();
    event.stopPropagation();

    const idToAdd = isFullView ? idToFetch : id;

    const token = localStorage.getItem('token');
    const isInFavorites = favorites?.indexOf(idToAdd) !== -1;
    const dataToSend = isInFavorites ?
      favorites?.filter(favorite => favorite !== idToAdd) :
      [...favorites, idToAdd];
    const favoritesToSend = dataToSend.filter(data => data.length).join(', ');

    try {
      const { data } = await axios.post(`${API_URLS.staff}/favorites`, {
        id: user?.userDrawingsId,
        favorites: favoritesToSend,
      }, {
        params: {
          token,
        }
      });

      if (data.successfully) {
        dispatch(setFavorites(
          {
            favorites: favoritesToSend.length ? favoritesToSend.split(', ') : [],
            isFavoritesLoading: false
          }
        ));
      } if (data.message === 'Errors.Auth.TokenExpired') {
        dispatch(setIsWaningModalOpen({ isOpen: true, variant: 'expired'}));
        dispatch(setUser({ user: null }));
        localStorage.removeItem('token');
      }
    } catch (error) {
      dispatch(setIsWaningModalOpen({ isOpen: true, variant: 'general'}));
    }
  }, [dispatch, favorites, id, idToFetch, isFullView, user?.userDrawingsId]);

  const onFavoriteClickWithoutUser = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();

    dispatch(setIsModalOpen({ isOpen: true }));
  }, [dispatch]);

  const onAddToCartClickWithoutUser = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();

    const isWantToLogIn = localStorage.getItem('isWantToLogIn');

    if (!isWantToLogIn) {
      dispatch(setIsWantToLoginModalOpen({ isWantToLoginModalOpen: true }));
    } else {
      const idToAdd = isFullView ? idToFetch : id;
      const isInCart = localCart?.indexOf(idToAdd) !== -1;
      const dataToSave = isInCart ?
        localCart?.filter(cartItem => cartItem !== idToAdd) :
        [...localCart, idToAdd];
      const localCartToSave = dataToSave.filter(data => data.length);
      const localCartToSaveString = localCartToSave.join(', ');

      dispatch(setLocalCart({ localCart: localCartToSave }));
      localStorage.setItem('localCart', localCartToSaveString);
    }
  }, [dispatch, id, idToFetch, isFullView, localCart]);

  const onAddToCartClickWithUser = useCallback(async (event) => {
    event.preventDefault();
    event.stopPropagation();

    const token = localStorage.getItem('token');

    const idToAdd = isFullView ? idToFetch : id;
    const dataToSend = [...cart, idToAdd];
    const cartToSend = dataToSend.filter(data => data.length).join(', ');

    try {
      const { data } = await axios.post(`${API_URLS.staff}/cart`, {
        id: user?.userDrawingsId,
        cart: cartToSend,
      }, {
        params: {
          token,
        }
      });

      if (data.successfully) {
        dispatch(setCart(
          {
            cart: cartToSend.length ? cartToSend.split(', ') : [],
            isCartLoading: false
          }
        ));
      } else if (data.message === 'Errors.Auth.TokenExpired') {
        dispatch(setIsWaningModalOpen({ isOpen: true, variant: 'expired'}));
        dispatch(setUser({ user: null }));
        localStorage.removeItem('token');
      }
    } catch (error) {
      dispatch(setIsWaningModalOpen({ isOpen: true, variant: 'general'}));
    }
  }, [cart, dispatch, id, idToFetch, isFullView, user?.userDrawingsId]);

  const onDownloadClick = useCallback(async (event) => {
    event.preventDefault();
    event.stopPropagation();

    try {
      const token = localStorage.getItem('token');

      const response = await axios.get(`${API_URLS.files}/user/${id}/${fileName}?token=${token}`, { responseType: 'blob' });

      const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      const fileLink = document.createElement('a');

      fileLink.href = fileURL;
      fileLink.setAttribute('download', fileName);
      fileLink.setAttribute('target', '_blank');

      document.body.appendChild(fileLink);

      fileLink.click();
      fileLink.remove();
    } catch (err) {
      const res = JSON.parse(await err.response.data.text());

      if (res.error === 'access') {
        dispatch(setIsWaningModalOpen({ isOpen: true, variant: 'access'}));
      } else {
        dispatch(setIsWaningModalOpen({ isOpen: true, variant: 'general'}));
      }
    }
  }, [dispatch, fileName, id]);

  useEffect(() => {
    const idToFind = isFullView ? idToFetch : id;
    const favoritesIndex = favorites?.indexOf(idToFind);

    if (favoritesIndex !== -1) {
      setIsInFavorite(true);
    } else {
      setIsInFavorite(false);
    }
  }, [favorites, id, idToFetch, isFullView]);

  useEffect(() => {
    const idToFind = isFullView ? idToFetch : id;
    const purchasesIndex = purchases?.indexOf(idToFind);

    if (purchasesIndex !== -1) {
      setIsInPurchases(true);
    } else {
      setIsInPurchases(false);
    }
  }, [id, idToFetch, isFullView, purchases]);

  useEffect(() => {
    const cartToFind = user ? cart : localCart;
    const idToFind = isFullView ? idToFetch : id;

    const cartItemIndex = cartToFind?.indexOf(idToFind);

    if (cartItemIndex !== -1) {
      setIsInCart(true);
    } else {
      setIsInCart(false);
    }
  }, [cart, id, idToFetch, isFullView, localCart, user]);

  const onDrawingClick = useCallback(() => {
    navigate(`${id}`);
  }, [id, navigate]);

  const fetchDrawing = useCallback(async () => {
    try {
      const { data } = await axios.get(`${API_URLS.drawings}/all-see/${idToFetch}`);

      if (data.successfully) {
        const pictures = data.drawing.picturesNames.split(', ');

        setCurrentData(data.drawing);
        setCurrentImages(pictures);
      } else {
        if (data.message === 'Errors.Auth.TokenExpired') {
          dispatch(setIsWaningModalOpen({ isOpen: true, variant: 'expired'}));
          dispatch(setUser({ user: null }));
          localStorage.removeItem('token');
        } else {
          dispatch(setIsWaningModalOpen({ isOpen: true, variant: 'notExist'}));
        }
      }
    } catch (error) {
      dispatch(setIsWaningModalOpen({ isOpen: true, variant: 'general'}));
    }
  }, [dispatch, idToFetch]);

  const onAddToCartClick = useCallback((event) => {
    if (user) {
      onAddToCartClickWithUser(event);
    } else {
      onAddToCartClickWithoutUser(event);
    }
  }, [onAddToCartClickWithUser, onAddToCartClickWithoutUser, user]);

  const onGoToCartClick = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    dispatch(setIsNeedToFetchCart({ isNeedToFetchCart: true }));

    navigate('/cart');
  }, [navigate]);

  const onDeleteFromCartClick = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();

    onDeleteItemClick(event, drawing.id);
  }, [drawing.id, onDeleteItemClick]);

  useEffect(() => {
    if (isFullView) {
      fetchDrawing();
    }
  }, [fetchDrawing, isFullView]);

  if (isFullView) {
    return (
      <div className="contentWrapper">
        <div className="d-flex flex-column">
          {currentImages.length !== 0 && (
            <div className="gallery mb-4">
              {currentImages.map((image) => (
                <Image
                  src={image ? `${API_URLS.publicFiles}/${image}` : noImage}
                  key={image}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src=noImage;
                  }}
                />
              ))}
            </div>
          )}
          {currentData && !isInPurchases && (
            <div className="d-flex flex-column">
              <div className="d-flex justify-content-between align-items-center">
                <div className="widthContentSmall fw-bold">
                  {currency === 'rub'  ? `${currentData.costRub} ₽` : `${currentData.costUsd} $`}
                </div>
                <Button
                  className="widthContent"
                  variant={isInCart ? "primary" : "success"}
                  onClick={isInCart ? onGoToCartClick : onAddToCartClick}
                >
                  {isInCart ? t('Drawing.GoToCart') : t('Drawing.AddToCart')}
                </Button>
                <Button
                  className="widthContent"
                  variant={isInFavorite ? "primary" : "success"}
                  onClick={user ? onFavoriteClickWithUser : onFavoriteClickWithoutUser}
                >
                  {isInFavorite ? t('Drawing.RemoveFromFavorite') : t('Drawing.AddToFavorite')}
                </Button>
              </div>
            </div>
          )}
          {isInPurchases && (
            <Button className="w-100" onClick={onDownloadClick}>
              {t('Drawing.Download')}
            </Button>
          )}
        </div>
        {currentData && (
          <div className="markdownWrapper">
            <Markdown>
              {locale === 'ru' ? currentData.ruLongDescription : currentData.enLongDescription}
            </Markdown>
          </div>
        )}
      </div>
    )
  }

  return (
    <div className="drawingCardWrapper" onClick={onDrawingClick}>
      {!isPurchases && (
        <div className="favoriteIcon" onClick={user ? onFavoriteClickWithUser : onFavoriteClickWithoutUser}>
          {isInFavorite ? <FilledHeart /> : <Heart />}
        </div>
      )}
      <Carousel className="carouselImagesWrapper" activeIndex={index} onSelect={handleSelect} interval={null}>
        {picturesURLs?.map((picture, index) => (
          <Carousel.Item key={picture}>
            <Image
              className="w-100 drawingPicture"
              src={picture ? `${API_URLS.publicFiles}/${picture}` : noImage}
              alt={`picture-${index}-${picture}`}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src=noImage;
              }}
            />
          </Carousel.Item>
        ))}
      </Carousel>
      {isValid && <div className="fw-bold">Видно при поиске!!!</div>}
      <p className="mt-3 mb-3 maxStrings">{locale === 'ru' ? ruDescription : enDescription}</p>
      {isInPurchases && (
        <Button className="w-100" onClick={onDownloadClick}>
          {t('Drawing.Download')}
        </Button>
      )}
      {isCart && (
        <div className="d-flex justify-content-between align-items-center w-100">
          <span className="fw-bold">{currency === 'rub' ? `${costRub} ₽` : `${costUsd} $`}</span>
          <Button className="w-75" variant="danger" onClick={(event) => onDeleteFromCartClick(event)}>
            {t('Drawing.Delete')}
          </Button>
        </div>
      )}
      {!isPurchases && !isCart && !isInPurchases && (
        <div className="d-flex justify-content-between align-items-center w-100">
          <span className="fw-bold">{currency === 'rub' ? `${costRub} ₽` : `${costUsd} $`}</span>
          {isAdmin ? (
            <Button
              className="w-75"
              variant="success"
              onClick={onDrawingClick}
            >
              Изменить
            </Button>
          ) : (
            <Button
              className="w-75"
              variant={isInCart ? "primary" : "success"}
              onClick={isInCart ? onGoToCartClick : onAddToCartClick}
            >
              {isInCart ? t('Drawing.GoToCart') : t('Drawing.AddToCart')}
            </Button>
          )}
        </div>
      )}
    </div>
  )
}
