import { useTranslation } from "react-i18next";

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import { ReactComponent as InfoIcon } from '../assets/icon-info.svg';

export const PasswordTooltip = () => {
  const { t } = useTranslation();

  const renderTooltip = (props) => (
    <Tooltip id="password-popover" {...props}>
      <p>{t('AuthModal.PasswordTooltip.Number')}</p>
      <p>{t('AuthModal.PasswordTooltip.Lowercase')}</p>
      <p>{t('AuthModal.PasswordTooltip.Uppercase')}</p>
      <p>{t('AuthModal.PasswordTooltip.Symbol')}</p>
      <p>{t('AuthModal.PasswordTooltip.Length')}</p>
    </Tooltip>
  );

  return (
    <OverlayTrigger
      placement="right"
      delay={{ show: 250, hide: 400 }}
      style={{width: '20rem'}}
      overlay={renderTooltip}
    >
      <span className="m-lg-2">
        <InfoIcon />
      </span>
    </OverlayTrigger>
  );
}
