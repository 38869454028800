import React, { useCallback } from 'react';
import { PayPalButtons } from "@paypal/react-paypal-js";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { v4 as uuidv4 } from 'uuid';

import {
  localeSelector,
  userSelector,
} from "../../../store/selectors";
import { setIsOrderModalOpen } from "../../../store/slices/orderModalSlice";
import { setTransactionId, setIsLoading, setLinks } from "../../../store/slices/confirmationSlice";

import { API_URLS } from "../../../consts";

export const PayPal = ({ drawings, totalCount, setCurrentItems }) => {
  const locale = useSelector(localeSelector);
  const user = useSelector(userSelector);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleApprove = async (order) => {
    try {
      dispatch(setIsLoading({ isLoading: true }));

      const { data } = await axios.post(`${API_URLS.order}/paypal/create`, {
        user,
        transactionId: order.id,
      });

      if (data.error) {
        dispatch(setIsOrderModalOpen({
          isOpen: true,
          variant: data.error === 'Order.NotExist' && 'notExist' ||
            data.error === 'Order.PleaseComplete' && 'complete' ||
            'common',
          transactionId: data.transactionId
        }));
      }

      if (data.successfully) {
        dispatch(setLinks({ links: data.linksPrefixes }));
        dispatch(setTransactionId({ transactionId: data.transactionId }));
        setCurrentItems([]);
        localStorage.setItem('linksPrefixes', data.linksPrefixes.join('&&&'));
        localStorage.setItem('transactionId', data.transactionId);
        localStorage.removeItem('localCart');
        navigate('/confirmation');
      }
    } catch (err) {
      dispatch(setIsOrderModalOpen({ isOpen: false, variant: 'tryAgain', transactionId: '' }));
    } finally {
      dispatch(setIsLoading({ isLoading: false }));
    }
  }

  const createOrder = useCallback((data, actions) => {
    const preparedDrawings = drawings.map(drawing => ({
      name: drawing.id,
      reference_id: drawing.id,
      unit_amount: {
        currency_code: 'USD',
        value: drawing.costUsd
      },
      quantity: 1
    }));

    return actions.order.create({
      purchase_units: [{
        amount: {
          currency_code: "USD",
          value: totalCount.usd,
          breakdown: {
            item_total: {
              currency_code: "USD",
              value: totalCount.usd
            }
          }
        },
        items: preparedDrawings,
        reference_id: uuidv4()
      }],
      intent: "CAPTURE"
    })
  }, [drawings, totalCount]);

  return (
    <PayPalButtons
      createOrder={createOrder}
      onApprove={async (data, actions) => {
        const order = await actions.order.capture();

        await handleApprove(order);
      }}
      onCancel={() => {
        dispatch(setIsOrderModalOpen({ isOpen: false, variant: 'tryAgain', transactionId: '' }));
      }}
      onError={(err) => {
        dispatch(setIsOrderModalOpen({ isOpen: false, variant: 'tryAgain', transactionId: '' }));
        console.log('err', err);
      }}
      forceReRender={[totalCount, drawings, locale]}
    />
  )
}
