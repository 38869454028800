import React, {useCallback} from "react";
import { useNavigate } from "react-router-dom";

import Button from "react-bootstrap/Button";

export const GoHomeButton = ({ title }) => {
  const navigate = useNavigate();

  const onButtonClick = useCallback(() => {
    navigate('/');
  }, [navigate]);

  return (
    <Button onClick={onButtonClick} variant="link">
      {title}
    </Button>
  )
};
