import { useCallback, useState } from "react";
import axios from "axios";
import { useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';

import { checkEmail, checkPassword } from '../../utils/auth';
import { setUser } from '../../store/slices/userSlice';
import { PasswordTooltip } from '../PasswordTooltip';
import { setIsWaningModalOpen } from "../../store/slices/warningModalSlice";

import { MAIN_COLOR, API_URLS } from '../../consts';

export const SignForm = ({ switchSignIn, isSignIn, closeModal }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isPasswordCompared, setIsPasswordCompared] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [comparePasswordError, setComparePasswordError] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const updateEmailField = useCallback((e) => {
    setEmail(String(e.target.value).trim().toLowerCase());
  }, [setEmail]);

  const updatePasswordField = useCallback((e) =>{
    setPassword(e.target.value);
  }, [setPassword]);

  const updateConfirmPasswordField = useCallback((e) => {
    setConfirmPassword(e.target.value)
  }, [setConfirmPassword]);

  const setEmailValidation = () => {
    const trimEmail = email.trim().toLowerCase();
    setEmail(trimEmail);

    if (checkEmail(trimEmail)) {
      setIsEmailValid(true);
      setEmailError('');
    } else {
      setIsEmailValid(false);
      setEmailError(t('AuthModal.EmailError'));
    }
  }

  const setComparePasswordIsValid = () => {
    setIsPasswordCompared(true);
    setComparePasswordError('');
  }

  const setComparePasswordIsNotValid = () => {
    setIsPasswordCompared(false);
    setComparePasswordError(t('AuthModal.ConfirmPasswordError'));
  }

  const setComparePasswordValidation = () => {
    if (password === confirmPassword) {
      setComparePasswordIsValid();
    } else {
      setComparePasswordIsNotValid();
    }
  }

  const setPasswordIsValid = () => {
    setPasswordError('');
    setIsPasswordValid(true);
  }

  const setPasswordIsNotValid = () => {
    setIsPasswordValid(false);
    setPasswordError(t('AuthModal.PasswordError'));
  }

  const setPasswordValidation = () => {
    if (isSignIn) {
      if (password.length) {
        setPasswordIsValid();
      } else {
        setPasswordIsNotValid();
      }
    } else if (checkPassword(password)) {
      setPasswordIsValid();
    } else {
      setPasswordIsNotValid();
    }

    if (!isSignIn) {
      setComparePasswordValidation();
    }
  }

  const setDefaultPasswordsStatuses = () => {
    setPasswordError('');
    setComparePasswordError('');
    setIsPasswordValid(false);
    setIsPasswordCompared(false);
  }

  const onSwitch = () => {
    if (!isSignIn) {
      if (password.length) {
        setComparePasswordIsValid();
        setPasswordIsValid();
      } else {
        setDefaultPasswordsStatuses();
      }
    } else {
      if (!password.length) {
        setDefaultPasswordsStatuses();
      }
      else if (checkPassword(password)) {
        setPasswordIsValid();

        if (password === confirmPassword) {
          setComparePasswordIsValid();
        } else {
          setComparePasswordIsNotValid();
        }
      } else {
        setPasswordIsNotValid();

        if (password === confirmPassword) {
          setComparePasswordIsValid();
        } else {
          setComparePasswordIsNotValid();
        }
      }
    }

    if (!email.length) {
      setIsEmailValid(false);
      setEmailError('');
    } else {
      setEmailValidation();
    }

    switchSignIn();
  }

  const isValidData = () => {
    setEmailValidation();
    setPasswordValidation();
    setAlertMessage('');

    if (isSignIn) {
      return checkEmail(email) && password.length;
    } else {
      return checkEmail(email) && checkPassword(password) && password === confirmPassword;
    }
  }

  const onSignIn = async () => {
    if (isValidData()) {
      try {
        const localCart = localStorage.getItem('localCart');

        const { data } = await axios.post(`${API_URLS.auth}/login`, {
            email,
            password,
            localCart,
          },
          {
            headers: {
              'Access-Control-Allow-Origin': '*',
              "Content-type": "application/json; charset=UTF-8",
            },
            withCredentials: true,
          });

        if (data.message === 'User logged in successfully.') {
          dispatch(setUser({ user: data.user }));
          localStorage.setItem('token', data.token);
          localStorage.removeItem('localCart');
          closeModal();
        } else {
          setAlertMessage(t(data.message));
        }
      } catch (e) {
        dispatch(setIsWaningModalOpen({ isOpen: true, variant: 'general'}));
      }
    }
  }

  const onSignUp = async () => {
    if (isValidData()) {
      try {
        const localCart = localStorage.getItem('localCart');

        const { data } = await axios.post(`${API_URLS.auth}/register`, {
            email,
            password,
            localCart,
          },
          {
            headers: {
              'Access-Control-Allow-Origin': '*',
              "Content-type": "application/json; charset=UTF-8",
            },
            withCredentials: true,
          });

        if (data.message === 'User registered successfully.') {
          dispatch(setUser({ user: data.user }));
          localStorage.setItem('token', data.token);
          localStorage.removeItem('localCart');
          closeModal();
        } else {
          setAlertMessage(t(data.message));
        }
      } catch (e) {
        dispatch(setIsWaningModalOpen({ isOpen: true, variant: 'general'}));
      }
    }
  }

  return (
    <>
      {alertMessage && (
        <Alert variant='danger'>
          {alertMessage}
        </Alert>
      )}
      <Form>
        <Form.Group>
          <Form.Group className="mb-3 position-relative" controlId="formSignInEmail">
            <Form.Label>{t('AuthModal.Email')}</Form.Label>
            <Form.Control
              type="email"
              placeholder={t('AuthModal.EmailPlaceholder')}
              isValid={isEmailValid}
              value={email}
              onChange={updateEmailField}
              onBlur={setEmailValidation}
            />
            {emailError && (
              <Form.Text
                className='position-absolute text-danger m-0'
                style={{
                  fontSize: '0.7rem'
                }}
              >
                {t('AuthModal.EmailError')}
              </Form.Text>
            )}
          </Form.Group>
          <Form.Group className="mb-3 position-relative" controlId="formSignOutEmail">
            <div className='"d-flex align-items-center mb-2'>
              <Form.Label className="mb-0">{t('AuthModal.Password')}</Form.Label>
              {!isSignIn && <PasswordTooltip />}
            </div>
            <Form.Control
              type="password"
              placeholder={t('AuthModal.PasswordPlaceholder')}
              isValid={isPasswordValid}
              value={password}
              onChange={updatePasswordField}
              onBlur={setPasswordValidation}
            />
            {passwordError && (
              <Form.Text
                className='position-absolute text-danger m-0'
                style={{
                  fontSize: '0.7rem'
                }}
              >
                {t('AuthModal.PasswordError')}
              </Form.Text>
            )}
          </Form.Group>
          {!isSignIn && (
            <Form.Group className="mb-3 position-relative">
              <Form.Label>{t('AuthModal.ConfirmPassword')}</Form.Label>
              <Form.Control
                type="password"
                placeholder={t('AuthModal.ConfirmPasswordPlaceholder')}
                isValid={isPasswordCompared}
                value={confirmPassword}
                onChange={updateConfirmPasswordField}
                onBlur={setComparePasswordValidation}
              />
              {comparePasswordError && (
                <Form.Text
                  className='position-absolute text-danger m-0'
                  style={{
                    fontSize: '0.7rem'
                  }}
                >
                  {t('AuthModal.ConfirmPasswordError')}
                </Form.Text>
              )}
            </Form.Group>
          )}
        </Form.Group>
        <div className='d-flex flex-column'>
          <Button
            variant='link'
            onClick={onSwitch}
            style={{padding: '12px 0'}}
          >
            {isSignIn ? t('AuthModal.GoToSignUp') : t('AuthModal.GoToSignIn')}
          </Button>
          <Button
            style={{backgroundColor: MAIN_COLOR, border: 'none'}}
            onClick={isSignIn ? onSignIn : onSignUp}
          >
            {isSignIn ? t('AuthModal.SignIn') : t('AuthModal.SignUp')}
          </Button>
        </div>
      </Form>
    </>
  )
}
