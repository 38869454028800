import React, { useCallback, useState, useMemo } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import Alert from "react-bootstrap/Alert";

import { prepareData } from '../../../utils/workWithDataSelect';
import { setIsWaningModalOpen } from "../../../store/slices/warningModalSlice";
import { setUser } from "../../../store/slices/userSlice";

import { FILTERS_URLS } from "../../../consts";

export const CreateDependencies = (
  { byPartOfBuilding, byPartOfDrawing, byNode, byDocumentationSection, dependenciesCombinations }
) => {
  const byDocumentationSectionSelect = useMemo(() => (
    byDocumentationSection.map(value => ({
      value: value.id,
      label: `${value.ruvalue} ${value.envalue}`
    }))
  ), [byDocumentationSection]);
  const byPartOfBuildingSelect = useMemo(() => prepareData(byPartOfBuilding), [byPartOfBuilding]);
  const byPartOfDrawingSelect = useMemo(() => prepareData(byPartOfDrawing), [byPartOfDrawing]);
  const byNodeSelect = useMemo(() => prepareData(byNode), [byNode]);
  const [preparedDependenciesCombinations, setPreparedDependenciesCombinations] = useState(
    dependenciesCombinations.map(combination => ({
      id: combination.id,
      documentationSectionId: combination.documentationsectionid,
      documentationSectionName: combination.documentationsectionname,
      byPartOfBuildingListName: combination.bypartofbuildinglistname,
      byPartOfDrawingListName: combination.bypartofdrawinglistname,
      byNodeListName: combination.bynodelistname,
    }))
  );
  const [currentDependenciesCombinations, setCurrentDependenciesCombinations] = useState([]);
  const [alertMessage, setAlertMessage] = useState('');
  const [typeOfMessage, setTypeOfMessage] = useState('success');

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onAddButtonClick = useCallback(() => {
    setCurrentDependenciesCombinations([
      ...currentDependenciesCombinations,
      {
        byDocumentationSectionSelect,
        byPartOfBuildingSelect,
        byPartOfDrawingSelect,
        byNodeSelect,
        id: '',
        documentationSectionId: byDocumentationSectionSelect[0]?.value || '',
        documentationSectionName: byDocumentationSectionSelect[0]?.label || '',
        selectedByDocumentationSectionSelect: byDocumentationSectionSelect[0] || {},
        selectedByPartOfBuildingSelect: byPartOfBuildingSelect[0] || {},
        selectedByPartOfDrawingSelect: byPartOfDrawingSelect[0] || {},
        selectedByNodeSelect: byNodeSelect[0] || {},
      }
    ]);


  }, [
    byDocumentationSectionSelect,
    byNodeSelect,
    byPartOfBuildingSelect,
    byPartOfDrawingSelect,
    currentDependenciesCombinations
  ]);

  const onAddOrUpdateButtonClick = useCallback(async (index) => {
    const token = localStorage.getItem('token');

    setAlertMessage('');

    const data = {
      id: currentDependenciesCombinations[index].id,
      documentationSectionId: currentDependenciesCombinations[index].selectedByDocumentationSectionSelect.value,
      documentationSectionName: currentDependenciesCombinations[index].selectedByDocumentationSectionSelect.label,
      byPartOfBuildingListName: currentDependenciesCombinations[index].selectedByPartOfBuildingSelect.value,
      byPartOfDrawingListName: currentDependenciesCombinations[index].selectedByPartOfDrawingSelect.value,
      byNodeListName: currentDependenciesCombinations[index].selectedByNodeSelect.value,
    };

    try {
      const result = await axios.post(FILTERS_URLS.updateDependencies, data, {
        params: {
          token,
        }
      });

      if (result.data.successfully) {
        setAlertMessage(result.data.message);
        setTypeOfMessage('success');

        if (result.data.id) {
          const newValues = currentDependenciesCombinations.map((currentValue, internalIndex) => {
            if (index === internalIndex) {
              return {
                ...currentValue,
                id: result.data.id,
              }
            }

            return currentValue;
          });

          setCurrentDependenciesCombinations(newValues);
        }
      } else {
        if (result.data.message === 'Errors.Auth.TokenExpired') {
          dispatch(setIsWaningModalOpen({ isOpen: true, variant: 'expired'}));
          dispatch(setUser({ user: null }));
          localStorage.removeItem('token');
        } else {
          setAlertMessage(t(result.data.message));
          setTypeOfMessage('danger');
        }
      }
    } catch (error) {
      dispatch(setIsWaningModalOpen({ isOpen: true, variant: 'general'}));
    }
  }, [currentDependenciesCombinations, dispatch, t]);

  const onSelectChange = useCallback((event, index, type) => {
    const selectedValue = type === 'partOfBuilding' ?
      {
        dependencyKey : 'selectedByPartOfBuildingSelect',
        value: byPartOfBuildingSelect,
      } :
      type === 'partOfDrawing' ? {
        dependencyKey : 'selectedByPartOfDrawingSelect',
        value: byPartOfDrawingSelect,
      } :
      type === 'node' ? {
        dependencyKey : 'selectedByNodeSelect',
        value: byNodeSelect,
      } : {
        dependencyKey : 'selectedByDocumentationSectionSelect',
        value: byDocumentationSectionSelect,
      };
    const newValue = selectedValue.value
      .find(combination => combination.label === event.target.value) || [];

    const result = currentDependenciesCombinations.map((combination, internalIndex) => {
      if (internalIndex === index) {
        return selectedValue.dependencyKey === 'selectedByDocumentationSectionSelect' ? {
            ...combination,
            documentationSectionId: newValue.value,
            documentationSectionName: newValue.label,
            [selectedValue.dependencyKey]: newValue
          } : {
            ...combination,
            [selectedValue.dependencyKey]: newValue
          }
      }

      return {
        ...combination,
      }
    });

    setCurrentDependenciesCombinations(result);
  }, [
    byDocumentationSectionSelect,
    byNodeSelect,
    byPartOfBuildingSelect,
    byPartOfDrawingSelect,
    currentDependenciesCombinations
  ]);

  const onDeleteExisted = useCallback(async (id, index) => {
    const token = localStorage.getItem('token');

    setAlertMessage('');

    const filteredValues = preparedDependenciesCombinations.filter((currentValue, internalIndex) => (
      index !== internalIndex
    ));

    try {
      const result = await axios.delete(FILTERS_URLS.deleteDependencies, {
        params: {
          token,
          id,
        }
      });

      if (result.data.successfully) {
        setAlertMessage(result.data.message);
        setTypeOfMessage('success');
        setPreparedDependenciesCombinations(filteredValues);
      } else {
        if (result.data.message === 'Errors.Auth.TokenExpired') {
          dispatch(setIsWaningModalOpen({ isOpen: true, variant: 'expired'}));
          dispatch(setUser({ user: null }));
          localStorage.removeItem('token');
        } else {
          setAlertMessage(t(result.data.message));
          setTypeOfMessage('danger');
        }
      }
    } catch (error) {
      dispatch(setIsWaningModalOpen({ isOpen: true, variant: 'general'}));
    }
  }, [dispatch, preparedDependenciesCombinations, t]);

  const onDelete = useCallback(async (id, index) => {
    const token = localStorage.getItem('token');

    setAlertMessage('');

    const filteredValues = currentDependenciesCombinations.filter((currentValue, internalIndex) => (
      index !== internalIndex
    ));

    try {
      const result = await axios.delete(FILTERS_URLS.deleteDependencies, {
        params: {
          token,
          id,
        }
      });

      if (result.data.successfully) {
        setAlertMessage(result.data.message);
        setTypeOfMessage('success');
        setCurrentDependenciesCombinations(filteredValues);
      } else {
        if (result.data.message === 'Errors.Auth.TokenExpired') {
          dispatch(setIsWaningModalOpen({ isOpen: true, variant: 'expired'}));
          dispatch(setUser({ user: null }));
          localStorage.removeItem('token');
        } else {
          setAlertMessage(t(result.data.message));
          setTypeOfMessage('danger');
        }
      }
    } catch (error) {
      dispatch(setIsWaningModalOpen({ isOpen: true, variant: 'general'}));
    }
  }, [currentDependenciesCombinations, dispatch, t]);

  return (
    <div className="d-flex flex-column" style={{maxHeight: '500px', overflowY: 'auto'}}>
      <Alert variant='warning'>
        Прежде чем закрыть нажать на кнопку СОХРАНИТЬ!
      </Alert>
      {alertMessage && (
        <Alert variant={typeOfMessage}>
          {alertMessage}
        </Alert>
      )}
      {preparedDependenciesCombinations.length === 0 && currentDependenciesCombinations.length === 0 ? (
        <span className="mb-2">Пусто</span>
      ) : (
        <>
          <span className="mb-2">Созданные записи</span>
          <div className="d-flex flex-column">
            {preparedDependenciesCombinations.map((combination, index) => (
              <div key={combination.id} className='d-flex justify-content-between mb-2 align-items-center'>
                <span>{combination.documentationSectionName}</span>
                <span>{combination.byPartOfBuildingListName}</span>
                <span>{combination.byPartOfDrawingListName}</span>
                <span>{combination.byNodeListName}</span>
                <Button variant="danger" onClick={() => onDeleteExisted(combination.id, index)}>Удалить</Button>
              </div>
            ))}
          </div>
        </>
      )}
      {currentDependenciesCombinations.map((combination, index) => (
        <div className="d-flex flex-column mb-3" key={`${combination}-${index}`}>
          <div className="d-flex w-100 mb-2 flex-column align-items-start">
            <div className="d-flex w-100 mb-2 align-items-start">
              <Form.Select className="w-50" onChange={(event) => onSelectChange(event, index, '')}>
                {combination.byDocumentationSectionSelect?.map(documentationSection => (
                  <option key={documentationSection.value}>{documentationSection.label}</option>
                ))}
              </Form.Select>
              <div className="d-flex flex-column w-50 align-items-end">
                <Form.Select onChange={(event) => onSelectChange(event, index, 'partOfBuilding')}>
                  {combination.byPartOfBuildingSelect?.map(partOfBuilding => (
                    <option key={partOfBuilding.value}>{partOfBuilding.label}</option>
                  ))}
                </Form.Select>
                {combination?.selectedByPartOfBuildingSelect?.textValues && (
                  <div className="d-flex flex-column">
                    {combination.selectedByPartOfBuildingSelect.textValues?.map((value) => (
                      <span className="text-end" key={value}>{value}</span>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div className="d-flex w-100 align-items-start">
              <div className="d-flex flex-column w-50 align-items-end">
                <Form.Select onChange={(event) => onSelectChange(event, index, 'partOfDrawing')}>
                  {combination.byPartOfDrawingSelect?.map(partOfDrawing => (
                    <option key={partOfDrawing.value}>{partOfDrawing.label}</option>
                  ))}
                </Form.Select>
                {combination?.selectedByPartOfDrawingSelect?.textValues && (
                  <div className="d-flex flex-column">
                    {combination.selectedByPartOfDrawingSelect.textValues?.map((value) => (
                      <span className="text-end" key={value}>{value}</span>
                    ))}
                  </div>
                )}
              </div>
              <div className="d-flex flex-column w-50 align-items-end">
                <Form.Select onChange={(event) => onSelectChange(event, index, 'node')}>
                  {combination.byNodeSelect?.map(node => (
                    <option key={node.value}>{node.label}</option>
                  ))}
                </Form.Select>
                {combination.selectedByNodeSelect?.textValues && (
                  <div className="d-flex flex-column">
                    {combination.selectedByNodeSelect.textValues?.map((value) => (
                      <span className="text-end" key={value}>{value}</span>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <Button variant="success" onClick={() => onAddOrUpdateButtonClick(index)}>Сохранить</Button>
            <Button variant="danger" onClick={() => onDelete(combination.id, index)} style={{ marginLeft: '1rem' }}>Удалить</Button>
          </div>
        </div>
      ))}
      <Button onClick={onAddButtonClick}>Добавить</Button>
    </div>
  )
}
