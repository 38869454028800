import React, { useCallback, useMemo, useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux';

import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Form from "react-bootstrap/Form";

import { MultipleSelect } from '../../MultipleSelect/MultipleSelect';
import { changeStringToDataSelect, prepareDependencies } from '../../../utils/workWithDataSelect';
import { setIsWaningModalOpen } from '../../../store/slices/warningModalSlice';
import { setUser } from "../../../store/slices/userSlice";

import { FILTERS_URLS, allValueRu } from '../../../consts';

export const ByDependentBody = (
  { values, onModalClose, byBuildingDependencies, title, tableName }
) => {
  const preparedCurrentValues = useMemo(() => {
    if (values.length) {
      return values.map(value => ({
        id: value.id,
        name: value.name,
        ruValue: value.ruvalue,
        enValue: value.envalue,
        byBuildingDependencies: value?.bybuildingdependencies?.length ?
          changeStringToDataSelect(value.bybuildingdependencies) : [],
      }));
    }

    return [];
  }, [values]);
  const [currentValues, setCurrentValues] = useState(preparedCurrentValues);
  const [alertMessage, setAlertMessage] = useState('');
  const [typeOfMessage, setTypeOfMessage] = useState('success');
  const allDependencies = useMemo(() => {
    let allValues = '';

    if (byBuildingDependencies?.ruvalues?.length) {
      allValues += byBuildingDependencies.ruvalues;
    }

    if (byBuildingDependencies?.envalues?.length) {
      allValues += allValues.length ? '&&&' + byBuildingDependencies.envalues : byBuildingDependencies.envalues;
    }

    return [allValueRu, ...changeStringToDataSelect(allValues)];
  }, [byBuildingDependencies.envalues, byBuildingDependencies.ruvalues]);
  const [dependenciesForValue, setDependenciesForValue] = useState(
    currentValues.map(() => allDependencies)
  );
  const [allDependenciesSelected, setAllDependenciesSelected] = useState(
    currentValues.map(currentValue => currentValue.byBuildingDependencies)
  );

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onChangeValue = (event, index, isNameUpdating, language) => {
    const keyToChange = isNameUpdating ? 'name' : language === 'en' ? 'enValue' : 'ruValue';

    setAlertMessage('');

    const newValues = currentValues.map((currentValue, internalIndex) => {
      if (index === internalIndex) {
        return {
          ...currentValue,
          [keyToChange]: event.target.value,
        }
      }

      return currentValue;
    });

    setCurrentValues(newValues);
  };

  const onAddOrUpdateButtonClick = useCallback(async (value, index) => {
    const token = localStorage.getItem('token');

    setAlertMessage('');

    try {
      const result = await axios.post(FILTERS_URLS.updateByDependent, {
        ...value,
        byBuildingDependencies: prepareDependencies(allDependenciesSelected[index], allDependencies),
      }, {
        params: {
          token,
          tableName,
        }
      });

      if (result.data.successfully) {
        setAlertMessage(result.data.message);
        setTypeOfMessage('success');

        if (result.data.id) {
          const newValues = currentValues.map((currentValue, internalIndex) => {
            if (index === internalIndex) {
              return {
                ...currentValue,
                id: result.data.id,
              }
            }

            return currentValue;
          });

          setCurrentValues(newValues);
        }
      } else {
        if (result.data.message === 'Errors.Auth.TokenExpired') {
          dispatch(setIsWaningModalOpen({ isOpen: true, variant: 'expired'}));
        } else {
          setAlertMessage(t(result.data.message));
          setTypeOfMessage('danger');
        }
      }
    } catch (error) {
      dispatch(setIsWaningModalOpen({ isOpen: true, variant: 'general'}));
    }
  }, [allDependencies, allDependenciesSelected, currentValues, dispatch, t, tableName]);

  const onAddClick = useCallback(() => {
    setAlertMessage('');
    setCurrentValues([
      ...currentValues,
      {
        id: '',
        name: '',
        ruValue: '',
        enValue: '',
        byBuildingDependencies: [],
      },
    ]);

    setAllDependenciesSelected([...allDependenciesSelected, []]);
    setDependenciesForValue([...dependenciesForValue, allDependencies]);
  }, [allDependencies, allDependenciesSelected, currentValues, dependenciesForValue]);

  const onDelete = useCallback(async (id, index) => {
    const token = localStorage.getItem('token');

    setAlertMessage('');

    const filteredValues = currentValues.filter((currentValue, internalIndex) => (
      index !== internalIndex
    ));
    const filteredAllDependenciesSelected =
      allDependenciesSelected.filter((dependency, internalIndex) => internalIndex !== index);
    const filteredDependenciesForValue =
      dependenciesForValue.filter((dependency, internalIndex) => internalIndex !== index);

    if (!id) {
      setCurrentValues(filteredValues);
      setAllDependenciesSelected(filteredAllDependenciesSelected);
      setDependenciesForValue(filteredDependenciesForValue);

      return;
    }

    try {
      const result = await axios.delete(FILTERS_URLS.deleteByDependent, {
        params: {
          token,
          id,
          tableName,
        }
      });

      if (result.data.successfully) {
        setAlertMessage(result.data.message);
        setTypeOfMessage('success');
        setCurrentValues(filteredValues);
        setAllDependenciesSelected(filteredAllDependenciesSelected);
        setDependenciesForValue(filteredDependenciesForValue);
      } else {
        if (result.data.message === 'Errors.Auth.TokenExpired') {
          dispatch(setIsWaningModalOpen({ isOpen: true, variant: 'expired'}));
          dispatch(setUser({ user: null }));
          localStorage.removeItem('token');
        } else {
          setAlertMessage(t(result.data.message));
          setTypeOfMessage('danger');
        }
      }
    } catch (error) {
      dispatch(setIsWaningModalOpen({ isOpen: true, variant: 'general'}));
    }
  }, [allDependenciesSelected, currentValues, dependenciesForValue, dispatch, t, tableName]);

  const onSelectChange = useCallback((index, selected) => {
    setAllDependenciesSelected([
      ...allDependenciesSelected.filter((dependency, innerIndex) => innerIndex < index),
      selected,
      ...allDependenciesSelected.filter((dependency, innerIndex) => innerIndex > index),
    ]);
  }, [allDependenciesSelected]);

  return (
    <div className="d-flex flex-column" style={{maxHeight: '500px', overflowY: 'auto'}}>
      <Alert variant='warning'>
        Прежде чем закрыть нажать на кнопку СОХРАНИТЬ!
      </Alert>
      {alertMessage && (
        <Alert variant={typeOfMessage}>
          {alertMessage}
        </Alert>
      )}
      {!currentValues.length && <span className="mb-2">Пусто</span>}
      <div className="d-flex flex-column">
        {currentValues.map((currentValue, index) => (
          <div className="d-flex mb-2 flex-column" key={`currentValue-${index}`}>
            <div className="d-flex mb-2">
              <Form.Control
                type="text"
                placeholder="Название списка"
                value={currentValue.name}
                onChange={(event) => onChangeValue(event, index, true)}
                style={{
                  marginRight: '1rem',
                }}
              />
              <Form.Control
                type="text"
                placeholder={`${title} на русском`}
                value={currentValue.ruValue}
                onChange={(event) => onChangeValue(event, index, false, 'ru')}
                style={{
                  marginRight: '1rem',
                }}
              />
              <Form.Control
                type="text"
                placeholder={`${title} на английском`}
                value={currentValue.enValue}
                onChange={(event) => onChangeValue(event, index, false, 'en')}
              />
            </div>
            Зависимости
            {dependenciesForValue[index]?.length !== 0 && (
              <MultipleSelect
                onChange={onSelectChange.bind(null, index)}
                options={dependenciesForValue[index]}
                selectedValue={allDependenciesSelected[index]}
              />
            )}
            <div className="d-flex justify-content-between">
              <Button
                variant="success"
                onClick={() => onAddOrUpdateButtonClick(currentValue, index)}
              >
                Сохранить
              </Button>
              <Button variant="danger" onClick={() => onDelete(currentValue.id, index)}>Удалить</Button>
            </div>
          </div>
        ))}
        <Button onClick={onAddClick}>Добавить</Button>
      </div>
      <Button className="mt-4" onClick={onModalClose}>Закрыть</Button>
    </div>
  )
}
