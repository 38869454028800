import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import axios from "axios";

import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";

import {
  userLocalCart,
  userSelector,
  userIsCartLoading,
  userIsNeedToFetchCartCart
} from "../../store/selectors";
import { setIsWaningModalOpen } from "../../store/slices/warningModalSlice";
import { setUser } from "../../store/slices/userSlice";
import { setIsCartLoading, setIsNeedToFetchCart } from "../../store/slices/staffSlice";
import { useCart } from "../../hooks/useCart";

import { DrawingsList } from '../DrawingsList/DrawingsList';

import { API_URLS } from "../../consts";

import './styles.css';

export const CartDrawingsList = ({ setTotalCount, currentItems, setCurrentItems }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [alertMessage, setAlertMessage] = useState('');

  const {
    onEmptyCartClick,
    onDeleteItemClick,
  } = useCart(currentItems, setCurrentItems, setAlertMessage);

  const localCart = useSelector(userLocalCart);
  const user = useSelector(userSelector);
  const isCartLoading = useSelector(userIsCartLoading);
  const isNeedToFetchCart = useSelector(userIsNeedToFetchCartCart);

  const fetchCart = useCallback(async () => {
    const token = localStorage.getItem('token');
    const linkToFetch = user ? `${API_URLS.staff}/with-login/cart` : `${API_URLS.staff}/without-login/cart`;
    const paramsToFetch = user ? {
      token,
      id: user.userDrawingsId,
    } : {
      cartItems: localCart.join(', '),
    }

    try {
      dispatch(setIsCartLoading({ isCartLoading: true }));

      const { data } = await axios.get(linkToFetch, {
        params: paramsToFetch,
      });

      if (data.successfully) {
        setCurrentItems(data.drawings);
        setTotalCount(data.totalCost);
      } else if (data.message === 'Errors.Auth.TokenExpired') {
        dispatch(setIsWaningModalOpen({ isOpen: true, variant: 'expired'}));
        dispatch(setUser({ user: null }));
        localStorage.removeItem('token');
      } else {
        setAlertMessage(t(data.message));
      }
    } catch (err) {
      dispatch(setIsWaningModalOpen({ isOpen: true, variant: 'general'}));
    } finally {
      dispatch(setIsCartLoading({ isCartLoading: false }));
    }
  }, [dispatch, localCart, setCurrentItems, setTotalCount, t, user]);

  useEffect(() => {
    if (isNeedToFetchCart) {
      fetchCart();
      dispatch(setIsNeedToFetchCart({ isNeedToFetchCart: false }));
    }
  }, [dispatch, fetchCart, isNeedToFetchCart]);

  return (
    <div className="d-flex flex-column">
      {alertMessage && (
        <Alert variant='danger'>
          {alertMessage}
        </Alert>
      )}
      <div className="d-flex align-items-end mb-5">
        <h2 className="mb-0">
          {t('Cart.Title')}
        </h2>
        <Button
          variant="link"
          onClick={onEmptyCartClick}
          className="p-0"
          style={{ marginLeft: '1rem', textDecoration: 'none' }}
        >
          {t('Cart.EmptyCart')}
        </Button>
      </div>
      {isCartLoading ? (
        <div className="cartDrawingListEmptyWrapper">
          <Spinner />
        </div>
      ) : (
        <DrawingsList
          drawings={currentItems}
          isCart
          onDeleteItemClick={onDeleteItemClick}
          voidMessage={t('Cart.VoidCart')}
        />
      )}
    </div>
  )
}
