import { createSlice } from '@reduxjs/toolkit';

const initialState = { isLoading: false, links: [], transactionId: '' };

const confirmationSlice = createSlice({
  name: 'confirmation',
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload.isLoading;
    },
    setLinks: (state, action) => {
      state.links = action.payload.links;
    },
    setTransactionId: (state, action) => {
      state.transactionId = action.payload.transactionId;
    }
  },
});

export const { setIsLoading, setLinks, setTransactionId } = confirmationSlice.actions
export default confirmationSlice.reducer
