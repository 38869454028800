import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Page } from '../../hoc/Page';
import { ContentWrapper } from '../../hoc/ContentWrapper';

export const PageNotFound = () => {
  const { t } = useTranslation();

  return (
    <Page>
      <ContentWrapper>
        <div className="d-flex flex-column align-items-center mt-5">
          <h1>{t('PageNotFound.Title')}</h1>
          <h4><NavLink to="/">{t('PageNotFound.NavLink')}</NavLink></h4>
        </div>
      </ContentWrapper>
    </Page>
  )
}
