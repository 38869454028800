import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

import { setIsCartModalOpen, setIsNeedToFetchCart } from '../../store/slices/staffSlice';
import { useCart } from "../../hooks/useCart";

import { CartItem } from './CartItem/CartItem';

import './styles.css';

export const CartModal = ({ currentItems, setCurrentItems, alertMessage, setAlertMessage, ...props}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { onDeleteItemClick, onEmptyCartClick } = useCart(currentItems, setCurrentItems, setAlertMessage);

  const onHide = useCallback(() => {
    dispatch(setIsCartModalOpen({ isCartModalOpen: false }));
  }, [dispatch]);

  const onGotoSearchClick = useCallback(() => {
    dispatch(setIsCartModalOpen({ isCartModalOpen: false }));
    dispatch(setIsNeedToFetchCart({ isNeedToFetchCart: true }));

    navigate('/');
  }, [dispatch, navigate]);

  const onGoToCartClick = useCallback(() => {
    dispatch(setIsCartModalOpen({ isCartModalOpen: false }));
    navigate('/cart');
  }, [dispatch, navigate]);

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      size="lg"
      centered
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t('CartModal.Title')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="cartModalBody">
          {alertMessage && (
            <Alert variant='danger'>
              {alertMessage}
            </Alert>
          )}
          {currentItems.length === 0 ? (
            <div className="d-flex align-items-center justify-content-center h-100">
              <span style={{ marginRight: '0.5rem' }}>
                {t('CartModal.EmptyCart')}
              </span>
              <Button variant="link" onClick={onGotoSearchClick}>{t('CartModal.GoHome')}</Button>
            </div>
          ) : (
            <div className="d-flex flex-column justify-content-between h-100">
              <div className="d-flex flex-column">
                {currentItems.map((item) => (
                  <CartItem cartItem={item} onDeleteCartItem={onDeleteItemClick} key={item.id}/>
                ))}
              </div>
              <div className="d-flex mt-3">
                <Button className="w-50" variant="danger" style={{ marginRight: '1rem' }} onClick={onEmptyCartClick}>
                  {t('CartModal.EmptyButton')}
                </Button>
                <Button className="w-50" variant="success" style={{marginLeft: '1rem' }} onClick={onGoToCartClick}>
                  {t('CartModal.GoToCart')}
                </Button>
              </div>
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}