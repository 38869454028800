import React, {useCallback, useMemo, useState} from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Form from "react-bootstrap/Form";

import { setIsWaningModalOpen } from "../../../store/slices/warningModalSlice";
import { setUser } from "../../../store/slices/userSlice";

import { sortByRuValue } from '../../../utils/sort';

import { FILTERS_URLS } from '../../../consts';

export const ByDocumentationSection = ({ values, onModalClose }) => {
  const sortedValues = useMemo(() => {
    if (values) {
      return [...values].sort(sortByRuValue);
    }

    return [];
  }, [values]);
  const [currentValues, setCurrentValues] = useState(sortedValues);
  const [alertMessage, setAlertMessage] = useState('');
  const [typeOfMessage, setTypeOfMessage] = useState('success');

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onChangeValue = (event, index, language) => {
    const keyToChange = language === 'en' ? 'envalue' : 'ruvalue';

    setAlertMessage('');

    const newValues = currentValues.map((currentValue, internalIndex) => {
      if (index === internalIndex) {
        return {
          ...currentValue,
          [keyToChange]: event.target.value,
        }
      }

      return currentValue;
    });

    setCurrentValues(newValues);
  };

  const onAddOrUpdateButtonClick = useCallback(async (value, index) => {
    const token = localStorage.getItem('token');

    setAlertMessage('');

    try {
      const result = await axios.post(FILTERS_URLS.updateByDocumentationSection, {
        id: value.id,
        ruValue: value.ruvalue,
        enValue: value.envalue,
      }, {
        params: {
          token,
        }
      });

      if (result.data.successfully) {
        setAlertMessage(result.data.message);
        setTypeOfMessage('success');

        if (result.data.id) {
          const newValues = currentValues.map((currentValue, internalIndex) => {
            if (index === internalIndex) {
              return {
                ...currentValue,
                id: result.data.id,
              }
            }

            return currentValue;
          });

          setCurrentValues(newValues);
        }
      } else {
        if (result.data.message === 'Errors.Auth.TokenExpired') {
          dispatch(setIsWaningModalOpen({ isOpen: true, variant: 'expired'}));
          dispatch(setUser({ user: null }));
          localStorage.removeItem('token');
        } else {
          setAlertMessage(t(result.data.message));
          setTypeOfMessage('danger');
        }
      }
    } catch (error) {
      dispatch(setIsWaningModalOpen({ isOpen: true, variant: 'general'}));
    }
  }, [currentValues, dispatch, t]);

  const onAddClick = useCallback(() => {
    setCurrentValues([
      ...currentValues,
      {
        id: '',
        ruvalue: '',
        envalue: '',
      },
    ]);
  }, [currentValues]);

  const onDeleteDocumentationSection = useCallback(async (id, index) => {
    const token = localStorage.getItem('token');

    setAlertMessage('');

    const filteredValues = currentValues.filter((currentValue, internalIndex) => (
      index !== internalIndex
    ));

    if (!id) {
      setCurrentValues(filteredValues);

      return;
    }

    try {
      const result = await axios.delete(FILTERS_URLS.deleteByDocumentationSection, {
        params: {
          token,
          id,
        }
      });

      if (result.data.successfully) {
        setAlertMessage(result.data.message);
        setTypeOfMessage('success');
        setCurrentValues(filteredValues);
      } else {
        if (result.data.message === 'Errors.Auth.TokenExpired') {
          dispatch(setIsWaningModalOpen({ isOpen: true, variant: 'expired'}));
        } else {
          setAlertMessage(t(result.data.message));
          setTypeOfMessage('danger');
        }
      }
    } catch (error) {
      dispatch(setIsWaningModalOpen({ isOpen: true, variant: 'general'}));
    }
  }, [currentValues, dispatch, t]);

  return (
    <div className="d-flex flex-column">
      <Alert variant='warning'>
        Прежде чем закрыть нажать на кнопку СОХРАНИТЬ!
      </Alert>
      {alertMessage && (
        <Alert variant={typeOfMessage}>
          {alertMessage}
        </Alert>
      )}
      {!currentValues.length && <span className="mb-2">Пусто</span>}
      <div className="d-flex flex-column">
        {currentValues.map((currentValue, index) => (
          <div className="d-flex mb-2" key={`currentValue-${index}`}>
            <Form.Control
              type="text"
              placeholder="Раздел документации на русском"
              value={currentValue.ruvalue}
              onChange={(event) => onChangeValue(event, index, 'ru')}
              style={{
                marginRight: '1rem',
              }}
            />
            <Form.Control
              type="text"
              placeholder="Раздел документации на английском"
              value={currentValue.envalue}
              onChange={(event) => onChangeValue(event, index, 'en')}
              style={{
                marginRight: '1rem',
              }}
            />
            <Button
              variant="success"
              onClick={() => onAddOrUpdateButtonClick(currentValue, index)}
              style={{
                marginRight: '1rem',
              }}
            >
              Сохранить
            </Button>
            <Button variant="danger" onClick={() => onDeleteDocumentationSection(currentValue.id, index)}>Удалить</Button>
          </div>
        ))}
        <Button onClick={onAddClick}>Добавить</Button>
      </div>
      <Button className="mt-4" onClick={onModalClose}>Закрыть</Button>
    </div>
  )
};
