import React, {useCallback, useMemo, useState} from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

import { AddFiltersValues } from '../AddFiltersValues/AddFiltersValues';
import { setIsWaningModalOpen } from "../../../store/slices/warningModalSlice";
import { setUser } from "../../../store/slices/userSlice";

import { sortArrayOfStringsCallback } from '../../../utils/sort';

export const ByRuEnBody = ({ ruValues, enValues, id, URL, titles }) => {
  const arrayFromRuValues = useMemo(() => {
    if (ruValues.length) {
      const listOfValues = ruValues.split('&&&');

      return [...listOfValues].sort(sortArrayOfStringsCallback);
    }

    return [];
  }, [ruValues]);
  const arrayFromEnValues = useMemo(() => {
    if (enValues.length) {
      const listOfValues = enValues.split('&&&');

      return [...listOfValues].sort(sortArrayOfStringsCallback);
    }

    return [];
  }, [enValues]);
  const [currentRuValues, setCurrentRuValues] = useState(arrayFromRuValues);
  const [currentEnValues, setCurrentEnValues] = useState(arrayFromEnValues);
  const [inputRuValue, setInputRuValue] = useState('');
  const [inputEnValue, setInputEnValue] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [alertVariant, setAlertVariant] = useState('');

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const updateInputRuValue = useCallback((event) => {
    setInputRuValue(event.target.value);
    setAlertMessage('');
  }, [setInputRuValue]);

  const onDeleteRuValue = useCallback((value) => {
    const filteredValues = currentRuValues.filter(currentValue => currentValue !== value);
    setCurrentRuValues(filteredValues);
    setAlertMessage('');
  }, [currentRuValues]);

  const onAddRuValue = useCallback(() => {
    if (inputRuValue) {
      setCurrentRuValues([...currentRuValues, inputRuValue]);
      setInputRuValue('');
      setAlertMessage('');
    }
  }, [inputRuValue, setInputRuValue, setCurrentRuValues, currentRuValues]);

  const updateInputEnValue = useCallback((event) => {
    setInputEnValue(event.target.value);
    setAlertMessage('');
  }, [setInputEnValue]);

  const onAddEnValue = useCallback(() => {
    if (inputEnValue) {
      setCurrentEnValues([...currentEnValues, inputEnValue]);
      setInputEnValue('');
      setAlertMessage('');
    }
  }, [inputEnValue, setInputEnValue, setCurrentEnValues, currentEnValues]);

  const onDeleteEnValue = useCallback((value) => {
    const filteredValues = currentEnValues.filter(currentValue => currentValue !== value);
    setCurrentEnValues(filteredValues);
    setAlertMessage('');
  }, [currentEnValues]);

  const onAddOrUpdateButtonClick = useCallback(async () => {
    const token = localStorage.getItem('token');

    setAlertMessage('');

    try {
      const result = await axios.post(URL, {
        id,
        ruValues: currentRuValues.length ? currentRuValues.join('&&&') : '',
        enValues: currentEnValues.length ? currentEnValues.join('&&&') : '',
      }, {
        params: {
          token,
        }
      });

      if (result.data.successfully) {
        setAlertMessage('Сохранено!');
        setAlertVariant('success');
      } else {
        if (result.data.message === 'Errors.Auth.TokenExpired') {
          dispatch(setIsWaningModalOpen({ isOpen: true, variant: 'expired'}));
          dispatch(setUser({ user: null }));
          localStorage.removeItem('token');
        } else {
          setAlertMessage(t(result.data.message));
          setAlertVariant('danger');
        }
      }
    } catch (error) {
      dispatch(setIsWaningModalOpen({ isOpen: true, variant: 'general'}));
    }
  }, [URL, id, currentRuValues, currentEnValues, dispatch, t])

  return (
    <div className="d-flex flex-column">
      {alertMessage && (
        <Alert variant={alertVariant}>
          {alertMessage}
        </Alert>
      )}
      <div className="d-flex justify-content-between w-100">
        <div className="w-50" style={{ marginRight: '1rem' }}>
          <AddFiltersValues
            values={currentRuValues}
            title={`${titles.first} на русском`}
            onDeleteValue={onDeleteRuValue}
            inputValue={inputRuValue}
            updateInputValue={updateInputRuValue}
            onAddValue={onAddRuValue}
            placeholder={`${titles.second} на русском`}
          />
        </div>
        <div className="w-50" style={{ marginLeft: '1rem' }}>
          <AddFiltersValues
            values={currentEnValues}
            title={`${titles.first} на английском`}
            onDeleteValue={onDeleteEnValue}
            inputValue={inputEnValue}
            updateInputValue={updateInputEnValue}
            onAddValue={onAddEnValue}
            placeholder={`${titles.second} на английском`}
          />
        </div>
      </div>
      <Button variant="success" onClick={onAddOrUpdateButtonClick} className="mt-4">Сохранить</Button>
    </div>
  )
};

