export const getIndexOfPoint = (string) => {
  if (!string) {
    return 0;
  }
  
  return string.indexOf('.');
}

export const numberFromString = (string, indexOfPoint) => {
  if (!string || !indexOfPoint) {
    return 0;
  }

  return Number(string.substring(0, indexOfPoint));
}

export const sortArrayOfStringsCallback = (prevString, currentString) => {
  const pointIndexPrev = getIndexOfPoint(prevString);
  const numberPrev = numberFromString(prevString, pointIndexPrev);
  const pointIndexCurrent = getIndexOfPoint(currentString);
  const numberCurrent = numberFromString(currentString, pointIndexCurrent);

  return numberPrev - numberCurrent;
}

export const getLastNumber = (arrayOfString) => {
  let lastNumber = 0;

  arrayOfString.forEach((string) => {
    const pointIndex = getIndexOfPoint(string);
    const number = numberFromString(string, pointIndex);

    if (number > lastNumber) {
      lastNumber = number;
    }
  });

  return lastNumber;
}

export const sortByRuValue = (prevValue, currentValue) => {
  const pointIndexPrev = getIndexOfPoint(prevValue.ruvalue);
  const numberPrev = numberFromString(prevValue.ruvalue, pointIndexPrev);
  const pointIndexCurrent = getIndexOfPoint(currentValue.ruvalue);
  const numberCurrent = numberFromString(currentValue.ruvalue, pointIndexCurrent);

  return numberPrev - numberCurrent;
}

export const sortByValue = (prevValue, currentValue) => {
  const pointIndexPrev = getIndexOfPoint(prevValue.value);
  const numberPrev = numberFromString(prevValue.value, pointIndexPrev);
  const pointIndexCurrent = getIndexOfPoint(currentValue.value);
  const numberCurrent = numberFromString(currentValue.value, pointIndexCurrent);

  return numberPrev - numberCurrent;
}

export const sortByName = (a, b) => a.localeCompare(b);
