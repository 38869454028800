import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: null,
  isUserLoading: true,
  isFavoritesLoading: true,
  isCartLoading: true,
  isPurchasesLoading: true,
  isWantToLoginModalOpen: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload.user;
    },
    setIsFavoritesLoading: (state, action) => {
      state.isFavoritesLoading = action.payload.isFavoritesLoading;
    },
    setIsCartLoading: (state, action) => {
      state.isCartLoading = action.payload.isCartLoading;
    },
    setIsPurchasesLoading: (state, action) => {
      state.isPurchasesLoading = action.payload.isPurchasesLoading;
    },
    setIsWantToLoginModalOpen: (state, action) => {
      state.isWantToLoginModalOpen = action.payload.isWantToLoginModalOpen;
    },
    setIsUserLoading: (state, action) => {
      state.isUserLoading = action.payload.isUserLoading;
    },
  },
});

export const {
  setUser,
  setIsFavoritesLoading,
  setIsPurchasesLoading,
  setIsWantToLoginModalOpen,
  setIsUserLoading,
  setIsCartLoading,
} = userSlice.actions
export default userSlice.reducer
