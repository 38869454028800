import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import { setIsWaningModalOpen } from '../../store/slices/warningModalSlice';
import { setIsModalOpen } from '../../store/slices/authModalSlice';
import { warningModalVariantSelector, userSelector } from '../../store/selectors';

export const WarningModal = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const variant = useSelector(warningModalVariantSelector);
  const user = useSelector(userSelector);

  const onGotoHomeClick = useCallback(() => {
    dispatch(setIsWaningModalOpen({ isOpen: false, variant: '' }));

    if (user?.isAdmin) {
      navigate('/admin');
    } else {
      navigate('/');
    }
  }, [dispatch, navigate, user?.isAdmin]);

  const onSignInClick = useCallback(() => {
    dispatch(setIsModalOpen({ isOpen: true }));
    dispatch(setIsWaningModalOpen({ isOpen: false, variant: '' }));
  }, [dispatch]);

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-warning"
      centered
      onHide={onGotoHomeClick}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t('WarningModal.Title')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column align-items-center">
          <p className="mb-5 mt-4">
            {variant === 'general' && t('WarningModal.TryAgainText')}
            {variant === 'expired' && t('WarningModal.ExpiredText')}
            {variant === 'signIn' && t('WarningModal.SignInText')}
            {variant === 'admin' && t('WarningModal.AdminText')}
            {variant === 'notExist' && t('WarningModal.VoidItem')}
            {variant === 'access' && t('WarningModal.Access')}
          </p>
          <div className={
            `d-flex 
             w-100 
             ${variant !== 'general' && variant !== 'admin' && variant !== 'notExist' && variant !== 'access' ?
              'justify-content-between' : 
              'justify-content-center'
            }`
          }>
            {variant !== 'general' && variant !== 'admin' && variant !== 'notExist' && variant !== 'access' && (
              <Button onClick={onSignInClick} variant="success">{t('WarningModal.SignInButton')}</Button>
            )}
            <Button onClick={onGotoHomeClick}>
              {user?.isAdmin ? t('WarningModal.GoToAdmin') : t('WarningModal.GoHome')}
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}