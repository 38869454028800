import { createSlice } from '@reduxjs/toolkit';

const initialState = { isOpen: false, variant: '', transactionId: '' };

const orderModalSlice = createSlice({
  name: 'orderModal',
  initialState,
  reducers: {
    setIsOrderModalOpen: (state, action) => {
      state.isOpen = action.payload.isOpen;
      state.variant = action.payload.variant;
      state.transactionId = action.payload.transactionId;
    }
  },
});

export const { setIsOrderModalOpen } = orderModalSlice.actions
export default orderModalSlice.reducer
