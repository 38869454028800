import React, {useCallback, useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";

import Button from 'react-bootstrap/Button';

import { Page } from '../../hoc/Page';
import { ContentWrapper } from '../../hoc/ContentWrapper';

import { userSelector } from '../../store/selectors';
import { setIsWaningModalOpen } from "../../store/slices/warningModalSlice";

import { API_URLS } from "../../consts";
import {setIsOrderModalOpen} from "../../store/slices/orderModalSlice";

export const Confirmation = () => {
  const [links, setLinks] = useState([]);
  const [transactionId, setTransactionId] = useState('');

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector(userSelector);

  useEffect(() => {
    const savedLinks = localStorage.getItem('linksPrefixes');
    const savedTransactionId = localStorage.getItem('transactionId');

    if (savedLinks) {
      setLinks(savedLinks.split('&&&'));
    }

    if (savedTransactionId) {
      setTransactionId(savedTransactionId);
    }
  }, []);

  const goHome = useCallback(() => {
    navigate('/');
  }, [navigate])

  const onLinkClick = useCallback(async (link) => {
    try {
      const response = await axios.get(`${API_URLS.order}/${link}`, { responseType: 'blob' });

      const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      const fileLink = document.createElement('a');
      const contentDisposition = response.headers['content-disposition'];
      const fileName = contentDisposition.substring(22, contentDisposition.length - 1);

      fileLink.href = fileURL;
      fileLink.setAttribute('download', fileName);
      fileLink.setAttribute('target', '_blank');

      document.body.appendChild(fileLink);

      fileLink.click();
      fileLink.remove();
    } catch (err) {
      const res = JSON.parse(await err.response.data.text());

      if (res.error === 'Order.Expired') {
        setLinks([]);
        setTransactionId('');
        localStorage.removeItem('linksPrefixes');
        localStorage.removeItem('transactionId');
      } else if (res.error) {
        dispatch(setIsOrderModalOpen({
          isOpen: true,
          variant: res.error === 'Order.NotExist' && 'notExist' ||
            res.error === 'Order.PleaseComplete' && 'complete' ||
            'common',
          transactionId: res.transactionId
        }));
      } else {
        dispatch(setIsWaningModalOpen({ isOpen: true, variant: 'general'}));
      }
    }
  }, [dispatch]);

  return (
    <Page>
      <ContentWrapper>
        <div className="d-flex flex-column align-items-center">
          <h2 className="mb-5">{t('Confirmation.Title')}</h2>
          {links.length !== 0 ? (
            <>
              <h3 className="mb-5">{t('Confirmation.ThankYou')}</h3>
              <h4 className="mb-5">{t('Confirmation.LinksToDownload')}</h4>
              <div className="d-flex flex-column mb-5">
                {links.map((link, index) => (
                  <Button
                    key={link}
                    variant="link"
                    onClick={() => onLinkClick(link)}
                    className="mb-3"
                  >
                    {`File ${index+1}`}
                  </Button>
                ))}
              </div>
              {user && <p>{t('Confirmation.UserWarning')}</p>}
              {transactionId && <p>{`${t('Confirmation.Transaction')}${transactionId}`}</p>}
            </>
          ) : (
            <div className="d-flex align-items-center">
              <p className="mb-0">{t('Confirmation.TimeExpired')}</p>
              <Button onClick={goHome} variant="link">{t('Confirmation.GoToSearch')}</Button>
            </div>
          )}
        </div>
      </ContentWrapper>
    </Page>
  )
}
