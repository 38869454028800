import { createSlice } from '@reduxjs/toolkit';

const initialState = { isOpen: false };

const authModalSlice = createSlice({
  name: 'authModal',
  initialState,
  reducers: {
    setIsModalOpen: (state, action) => {
      state.isOpen = action.payload.isOpen;
    }
  },
});

export const { setIsModalOpen } = authModalSlice.actions
export default authModalSlice.reducer
