import React from "react";

import Select from 'react-select';

const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: 'white' }),
  option: (styles) => {
    return {
      ...styles,
      color: 'black',
    };
  },
};

export const SingleSelect = ({ options, value, onChange, ...props }) => (
  <Select
    {...props}
    options={options}
    value={value}
    onChange={onChange}
    styles={colourStyles}
  />
);
