import React from "react";
import { useTranslation } from "react-i18next";

import { Page } from '../../hoc/Page';
import { ContentWrapper } from '../../hoc/ContentWrapper';

import { supportEmail } from "../../consts";

export const Participation = () => {
  const { t } = useTranslation();

  return (
    <Page>
      <ContentWrapper>
        {`${t('Participation.Text')} ${supportEmail}`}
      </ContentWrapper>
    </Page>
  )
}
