import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  favorites: [],
  purchases: [],
  cart: [],
  localCart: [],
  isFavoritesLoading: true,
  isCartLoading: true,
  isPurchasesLoading: true,
  isCartModalOpen: false,
  isNeedToFetchCart: true,
};

const staffSlice = createSlice({
  name: 'staff',
  initialState,
  reducers: {
    setFavorites: (state, action) => {
      state.favorites = action.payload.favorites;
    },
    setPurchases: (state, action) => {
      state.purchases = action.payload.purchases;
    },
    setCart: (state, action) => {
      state.cart = action.payload.cart;
    },
    setLocalCart: (state, action) => {
      state.localCart = action.payload.localCart;
    },
    setIsCartModalOpen: (state, action) => {
      state.isCartModalOpen = action.payload.isCartModalOpen;
    },
    setIsFavoritesLoading: (state, action) => {
      state.isFavoritesLoading = action.payload.isFavoritesLoading;
    },
    setIsCartLoading: (state, action) => {
      state.isCartLoading = action.payload.isCartLoading;
    },
    setIsPurchasesLoading: (state, action) => {
      state.isPurchasesLoading = action.payload.isPurchasesLoading;
    },
    setIsNeedToFetchCart: (state, action) => {
      state.isNeedToFetchCart = action.payload.isNeedToFetchCart;
    },
  },
});

export const {
  setFavorites,
  setPurchases,
  setCart,
  setLocalCart,
  setIsCartModalOpen,
  setIsFavoritesLoading,
  setIsPurchasesLoading,
  setIsCartLoading,
  setIsNeedToFetchCart,
} = staffSlice.actions;
export default staffSlice.reducer;
