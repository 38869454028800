import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';

import { userSelector } from "../../store/selectors";
import { Page } from '../../hoc/Page';
import { ContentWrapper } from '../../hoc/ContentWrapper';
import { UpdateDrawing } from  '../../components/UpdateDrawing/UpdateDrawing';
import { setIsWaningModalOpen } from "../../store/slices/warningModalSlice";

export const AdminProduct = () => {
  const user = useSelector(userSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!user) {
      dispatch(setIsWaningModalOpen({ isOpen: true, variant: 'admin'}));
    } else {
      dispatch(setIsWaningModalOpen({ isOpen: false, variant: ''}));
    }
  }, [dispatch, user]);

  return (
    <Page>
      <ContentWrapper>
        {user && user.isAdmin ? (
          <>
            <UpdateDrawing />
          </>
        ) : (
          <div>Not Admin</div>
        )}
      </ContentWrapper>
    </Page>
  )
}
