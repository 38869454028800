import React, { useCallback } from "react";

import Button from 'react-bootstrap/Button';

import { API_URLS } from "../../consts";

export const DownloadOffer = ({ text }) => {
  const onDownloadClick = useCallback(async () => {
    const response = await fetch(`${API_URLS.files}/offer`);

    if (response.status === 200) {
      const blob = await response.blob();
      const downloadURL = window.URL.createObjectURL(blob);
      const link = document.createElement('a');

      link.href = downloadURL;
      link.download = 'offer.docx';

      document.body.appendChild(link);

      link.click();
      link.remove();
    }
  }, []);

  return (
    <Button variant="link" onClick={onDownloadClick}>
      {text}
    </Button>
  )
}
