import React from "react";
import { useTranslation } from "react-i18next";

import { Page } from '../../hoc/Page';
import { ContentWrapper } from '../../hoc/ContentWrapper';

import { supportEmail } from "../../consts";

export const Contacts = () => {
  const { t } = useTranslation();

  return (
    <Page>
      <ContentWrapper>
          {t('Contacts.Name')}
          <br/>
          {t('Contacts.TaxNumber')}
          <br/>
          {`${t('Contacts.Text')} ${supportEmail}`}
      </ContentWrapper>
    </Page>
  )
}
