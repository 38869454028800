import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { setIsModalOpen } from "../../store/slices/authModalSlice";
import { userCart, userLocalCart, userSelector } from "../../store/selectors";

import Button from "react-bootstrap/Button";
import { Page } from '../../hoc/Page';
import { ContentWrapper } from '../../hoc/ContentWrapper';
import { GoHomeButton } from '../../components/GoHomeButton/GoHomeButton';
import { CartDrawingsList } from '../../components/CartDrawingsList/CartDrawingsList';
import { Payments } from '../../components/Payments/Payments';

import './styles.css';

export const initialTotalCount = {
  usd: 0,
  rub: 0,
};

export const Cart = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const localCart = useSelector(userLocalCart);
  const cart = useSelector(userCart);
  const user = useSelector(userSelector);

  const [totalCount, setTotalCount] = useState(initialTotalCount);
  const [currentItems, setCurrentItems] = useState([]);

  const onOpen = useCallback(() => {
    dispatch(setIsModalOpen({ isOpen: true }));
  }, [dispatch]);

  return (
    <Page>
      <ContentWrapper>
        {localCart.length === 0 && cart.length === 0 ? (
          <div className="voidCartWrapper mt-5">
            {t('Cart.VoidCart')}
            <GoHomeButton title={t('Cart.GoHome')}/>
          </div>
        ) : (
          <div className="d-flex w-100 mt-3">
            <div className="cartDrawingListWrapper">
              <CartDrawingsList
                setTotalCount={setTotalCount}
                currentItems={currentItems}
                setCurrentItems={setCurrentItems}
              />
            </div>
            <div className="cartPaymentsWrapper">
              {user ?
                <Payments
                  totalCount={totalCount}
                  drawings={currentItems}
                  setCurrentItems={setCurrentItems}
                /> :
                <>
                  <h2>{t('Cart.LogIn')}</h2>
                  <Button
                      className="w-100 mt-3"
                      variant="success"
                      onClick={onOpen}
                  >
                    {t('Header.User.SignIn')}
                  </Button>
                </>
              }
            </div>
          </div>
        )}
      </ContentWrapper>
    </Page>
  )
}
