import React from "react";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

export const AddFiltersValues = (
  { values, onDeleteValue, inputValue, updateInputValue, onAddValue, title, placeholder }
) => (
  <div className="d-flex flex-column">
    <p className="mb-2">
      {title}
    </p>
    <div className="d-flex flex-column">
      {!values.length && <span className="mb-2">Пусто</span>}
      {values.map(value => (
        <div className="d-flex justify-content-between align-items-center mb-2" key={value}>
          <span>{value}</span>
          <Button variant="danger" onClick={() => onDeleteValue(value)} style={{ marginLeft: '1rem' }}>Удалить</Button>
        </div>
      ))}
      <Form.Control
        type="text"
        placeholder={placeholder}
        value={inputValue}
        onChange={updateInputValue}
        className="mb-2"
      />
      <Button onClick={onAddValue}>Добавить</Button>
    </div>
  </div>
);
