import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  drawings: [],
  newestDrawings: [],
  adminSearchDrawings: [],
  adminAlertMessage: '',
  isSearched: false,
  searchParams: {},
  isAdminSearchByName: false,
  adminFindString: '',
};

const drawingsSlice = createSlice({
  name: 'drawings',
  initialState,
  reducers: {
    setDrawings: (state, action) => {
      state.drawings = action.payload.drawings;
    },
    setNewestDrawings: (state, action) => {
      state.newestDrawings = action.payload.newestDrawings;
    },
    setAdminSearchDrawings: (state, action) => {
      state.adminSearchDrawings = action.payload.adminSearchDrawings;
    },
    setAdminAlertMessage: (state, action) => {
      state.adminAlertMessage = action.payload.adminAlertMessage;
    },
    setIsSearched: (state, action) => {
      state.isSearched = action.payload.isSearched;
    },
    setSearchParams: (state, action) => {
      state.searchParams = action.payload.searchParams;
    },
    setIsAdminSearchByName: (state, action) => {
      state.isAdminSearchByName = action.payload.isAdminSearchByName;
    },
    setAdminFindString: (state, action) => {
      state.adminFindString = action.payload.adminFindString;
    },
  },
});

export const {
  setDrawings,
  setNewestDrawings,
  setAdminSearchDrawings,
  setAdminAlertMessage,
  setIsSearched,
  setSearchParams,
  setIsAdminSearchByName,
  setAdminFindString,
} = drawingsSlice.actions
export default drawingsSlice.reducer