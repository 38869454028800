import React, {useEffect} from "react";
import { useSelector, useDispatch } from 'react-redux';

import { userSelector, isUserLoadingSelector } from "../../store/selectors";
import { Page } from '../../hoc/Page';
import { ContentWrapper } from '../../hoc/ContentWrapper';
import { FavoritesDrawingsList } from '../../components/FavoritesDrawingsList/FavoritesDrawingsList';
import { PurchasesDrawingList } from '../../components/PurchasesDrawingList/PurchasesDrawingList';
import { setIsWaningModalOpen } from "../../store/slices/warningModalSlice";

export const Profile = () => {
  const user = useSelector(userSelector);
  const isUserLoading = useSelector(isUserLoadingSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!user && !isUserLoading) {
      dispatch(setIsWaningModalOpen({ isOpen: true, variant: 'signIn'}));
    } else {
      dispatch(setIsWaningModalOpen({ isOpen: false, variant: ''}));
    }
  }, [dispatch, isUserLoading, user]);

  return (
    <Page>
      <ContentWrapper>
        {user ? (
          <>
            <PurchasesDrawingList />
            <FavoritesDrawingsList />
          </>
        ) : (
          <div>No user</div>
        )}
      </ContentWrapper>
    </Page>
  )
}
