import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import { setIsWantToLoginModalOpen } from '../../store/slices/userSlice';
import { setIsModalOpen } from '../../store/slices/authModalSlice';

export const WantToLoginModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onHide = useCallback(() => {
    localStorage.setItem('isWantToLogIn', 'false');
    dispatch(setIsWantToLoginModalOpen({ isWantToLoginModalOpen: false }));
  }, [dispatch]);

  const onLogInClick = useCallback(() => {
    dispatch(setIsModalOpen({ isOpen: true }));
    dispatch(setIsWantToLoginModalOpen({ isWantToLoginModalOpen: false }));
  }, [dispatch]);

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t('WantToLoginModal.Title')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column">
          <span className="mb-5">{t('WantToLoginModal.Content')}</span>
          <div className="d-flex">
            <Button
              className="w-50"
              variant="success"
              style={{ marginRight: '1rem' }}
              onClick={onLogInClick}
            >
              {t('WantToLoginModal.Yes')}
            </Button>
            <Button
              className="w-50"
              style={{ marginLeft: '1rem' }}
              onClick={onHide}
            >
              {t('WantToLoginModal.No')}
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}