import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import Spinner from 'react-bootstrap/Spinner';

import { DrawingsList } from '../DrawingsList/DrawingsList';
import { GoHomeButton } from '../GoHomeButton/GoHomeButton';
import {
  userSelector,
  userFavoritesSelector,
  userIsFavoritesLoadingSelector,
} from "../../store/selectors";
import { setIsWaningModalOpen } from "../../store/slices/warningModalSlice";
import { setIsFavoritesLoading } from "../../store/slices/staffSlice";
import { setUser } from "../../store/slices/userSlice";

import { API_URLS } from '../../consts';

import '../styles.css';

export const FavoritesDrawingsList = () => {
  const favorites = useSelector(userFavoritesSelector);
  const isLoading = useSelector(userIsFavoritesLoadingSelector);
  const user = useSelector(userSelector);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [favoritesDrawings, setFavoritesDrawing] = useState([]);

  useEffect(() => {
    const fetchDrawings = async () => {
      const token = localStorage.getItem('token');

      try {
        const { data } = await axios.get(`${API_URLS.staff}/favorites`, {
          params: {
            token,
            id: user.userDrawingsId
          },
        });

        if (data.successfully) {
          setFavoritesDrawing(data.drawings);
          dispatch(setIsFavoritesLoading({ isFavoritesLoading: false }));
        } else {
          if (data.message === 'Errors.Auth.TokenExpired') {
            dispatch(setIsWaningModalOpen({ isOpen: true, variant: 'expired'}));
            dispatch(setUser({ user: null }));
            localStorage.removeItem('token');
          } else {
            dispatch(setIsWaningModalOpen({ isOpen: true, variant: 'access'}));
          }
        }
      } catch (error) {
        dispatch(setIsWaningModalOpen({ isOpen: true, variant: 'general'}));
      }
    }

    if (favorites && user) {
      fetchDrawings();
    }
  }, [dispatch, favorites, t, user]);

  return (
    <div className="d-flex flex-column">
      <h2 className="mb-4">
        {t('Profile.Favorites')}
      </h2>
      <div className="wrapperWithHalfWidth">
        {isLoading ? (
          <div className="wrapperWithHalfWidthAndBorder d-flex align-items-center justify-content-center w-100">
            <Spinner />
          </div>
        ) : (
          favoritesDrawings.length !== 0 ? (
            <DrawingsList drawings={favoritesDrawings} isFavorites isPurchases={false} isCart={false} />
          ) : (
            <div className="wrapperWithHalfWidthAndBorder d-flex align-items-center justify-content-center w-100">
              <span
                className="d-flex align-items-center"
              >
                {`${t('Profile.EmptyFavorites')}`}<GoHomeButton title={t('Profile.GoToSearchFavorites')}/>
              </span>
            </div>
          )
        )}
      </div>
    </div>
  )
}
