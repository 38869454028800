import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import { setIsOrderModalOpen } from '../../store/slices/orderModalSlice';
import { orderModalVariantSelector, orderModalTransactionIdSelector } from '../../store/selectors';

import { supportEmail } from "../../consts";

export const OrderModal = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const variant = useSelector(orderModalVariantSelector);
  const transactionId = useSelector(orderModalTransactionIdSelector);

  const onHide = useCallback(() => {
    dispatch(setIsOrderModalOpen({ isOpen: false, variant: '', transactionId: '' }));
  }, [dispatch]);

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-warning"
      centered
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t('Order.Title')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column align-items-center">
          <p className="mb-3 mt-4">
            {variant === 'common' && t('Order.Common')}
            {variant === 'notExist' && t('Order.NotExist')}
            {variant === 'complete' && t('Order.PleaseComplete')}
            {variant === 'tryAgain' && t('Order.TryAgain')}
          </p>
          {variant === 'common' && (
              <>
                <p className="mb-2">
                  {t('Order.LoadFromProfile')}.
                </p>
                <p className="mb-2">
                  {t('Order.SupportEmail')} {supportEmail}.
                </p>
                <p className="mb-5">
                  {t('Order.Transaction')} {transactionId}
                </p>
              </>
          )}
          {variant !== 'common' && variant !== 'tryAgain' && (
              <p className="mb-5">
              {t('Order.Transaction')} {transactionId}
            </p>
          )}
          <Button onClick={onHide}>{t('Order.CloseButton')}</Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}