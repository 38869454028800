import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Provider } from 'react-redux';
import { PayPalScriptProvider } from "@paypal/react-paypal-js";

import { Home } from './pages/home/Home';
import { Drawing } from './pages/drawing/Drawing';
import { Profile } from './pages/profile/Profile';
import { Info } from './pages/info/Info';
import { Contacts } from './pages/contacts/Contacts';
import { Participation } from './pages/participation/Participation';
import { Admin } from './pages/admin/Admin';
import { AdminProduct } from './pages/adminProduct/AdminProduct';
import { Cart } from './pages/cart/Cart';
import { PageNotFound } from './pages/pageNotFound/PageNotFound';
import { Confirmation } from './pages/confirmation/Confirmation';

import store from './store/store';

import './i18n';

const { REACT_APP_PAYPAL_CLIENT_ID } = process.env;

const App = () => {
  return (
    <PayPalScriptProvider
      options={
        { "client-id": REACT_APP_PAYPAL_CLIENT_ID }
      }
    >
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            <Route exact path='/' element={<Home />} />
            <Route path='/en' element={<Home />} />
            <Route path='/ru' element={<Home />} />
            <Route path='/profile' element={<Profile />} />
            <Route path='/info' element={<Info />} />
            <Route path='/contacts' element={<Contacts />} />
            <Route path='/participation' element={<Participation />} />
            <Route path='/cart' element={<Cart />} />
            <Route path='/confirmation' element={<Confirmation />} />
            <Route path='/admin' element={<Admin />}/>
            <Route path='/admin/:id' element={<AdminProduct />} />
            <Route path='/:id' element={<Drawing />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </BrowserRouter>
      </Provider>
    </PayPalScriptProvider>
  );
}

export default App;
