import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import Spinner from 'react-bootstrap/Spinner';

import { DrawingsList } from '../DrawingsList/DrawingsList';
import {
  searchNewestDrawingsSelector,
} from "../../store/selectors";
import { setNewestDrawings } from '../../store/slices/drawingsSlice';
import { setToInitial } from '../../store/slices/paginationSlice';
import { usePagination } from '../../hooks/usePagination';

import '../styles.css';

export const NewestDrawings = () => {
  const newestDrawings = useSelector(searchNewestDrawingsSelector);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { fetching, currentPage } = usePagination(true);

  useEffect(() => {
    return () => {
      dispatch(setNewestDrawings({ newestDrawings: [] }));
      dispatch(setToInitial());
    }
  }, [dispatch]);

  return (
    <>
      {currentPage === 1 && fetching ? (
        <div className="wrapperWithBorder">
          <Spinner/>
        </div>
      ) : (
        <div className="d-flex flex-column w-100">
          <h1 className="mb-5">{t('HomePage.NewestDrawings')}</h1>
          <DrawingsList
            drawings={newestDrawings}
            isCart={false}
            isPurchases={false}
            isFavorites={false}
            voidMessage={t('HomePage.NoDrawingsFound')}
            isPartialLoading={fetching}
          />
          <h4 className="mt-5 align-self-center">{t('HomePage.LetsSearch')}</h4>
        </div>
      )}
    </>
  )
}
