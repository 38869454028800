import {getIndexOfPoint, numberFromString, sortByName, sortByValue} from "./sort";

import { allValueRu, allValueEn } from '../consts';

export const changeStringToDataSelect = (string) => {
  if (!string) {
    return [];
  }

  const preparedData = string.split('&&&').map(value => ({
    value,
    label: value,
  }))

  return [...preparedData].sort(sortByValue);
};

export const changeDataSelectToString = (dataSelect) => dataSelect.map((value) => value.value).join('&&&');

export const prepareDependencies = (dataSelect, allDependencies) => {
  const isAllSelected = dataSelect.filter((value) => value.value === 'Все');

  if (isAllSelected.length) {
    return changeDataSelectToString(allDependencies);
  }

  return changeDataSelectToString(dataSelect);
}

export const prepareData = (values) => {
  const namesList = values.map(value => value.name);
  const uniqNames = new Set(namesList);

  return Array.from(uniqNames).map((name) => {
    const allValues = values.filter(value => value.name === name);

    return {
      value: name,
      label: name,
      textValues: allValues?.map(value => `${value.ruvalue} ${value.envalue}`) || []
    }
  });
};

export const prepareDataByLanguageWithId = (values, key) => {
  const preparedValues = values.map(value => ({
    value: value[key],
    label: value[key],
    id: value.id,
  }));

  return [...preparedValues].sort(sortByValue);
};

export const prepareDataByLanguageWithDependencies = (values, key) => {
  const preparedValues = values.map(value => ({
    value: value[key],
    label: value[key],
    dependencies: value.byBuildingDependencies,
  }));

  return [...preparedValues].sort(sortByValue);
};

export const getList = (values) => {
  const lists = values.map(value => {
    const ruValues = value.ruvalue.split(', ').map(value => value.trim()).sort(sortByName);
    const enValues = value.envalue.split(', ').map(value => value.trim()).sort(sortByName);

    return ruValues.map((ruValue, index) => ({
      name: value.name,
      ruValue: ruValue,
      enValue: enValues[index],
      byBuildingDependencies: value.bybuildingdependencies,
    }));
  });

  let result = {};

  lists.forEach((list) => {
    result = {
      ...result,
      [list[0].name]: {
        ru: [
          allValueRu,
          ...prepareDataByLanguageWithDependencies(list, 'ruValue'),
        ],
        en: [
          allValueEn,
          ...prepareDataByLanguageWithDependencies(list, 'enValue'),
        ],
      },
    };
  });

  return result;
}

export const getListWithoutAll = (values) => {
  const lists = values.map(value => {
    const ruValues = value.ruvalue.split(', ');
    const enValues = value.envalue.split(', ');

    return ruValues.map((ruValue, index) => ({
      name: value.name,
      ruValue: ruValue,
      enValue: enValues[index],
      byBuildingDependencies: value.bybuildingdependencies,
    }));
  });

  let result = {};

  lists.forEach((list) => {
    result = {
      ...result,
      [list[0].name]: {
        ru: prepareDataByLanguageWithDependencies(list, 'ruValue'),
        en: prepareDataByLanguageWithDependencies(list, 'enValue'),
      },
    };
  });

  return result;
}

export const getValue = (selectedValues, allValues) => selectedValues[0]?.value === 'Все' ?
  changeDataSelectToString(allValues) :
  changeDataSelectToString(selectedValues);

export const getDataSelectByDocumentationSection = (
    selectedDocumentationSections,
    byPartOfBuildingValues,
    byPartOfDrawingValues,
    byNodeValues,
    preparedCombinations,
) => {
  let includedIndexes = [];
  let byPartOfBuildingResult = [];
  let byPartOfDrawingResult = [];
  let byNodeResult = [];

  selectedDocumentationSections.forEach(({ value }) => {
    const indexOfPoint = getIndexOfPoint(value);
    const numberOfDocumentationSection = numberFromString(value, indexOfPoint);

    if (!includedIndexes.includes(numberOfDocumentationSection)) {
      includedIndexes = [...includedIndexes, numberOfDocumentationSection];

      for (const key in preparedCombinations) {
        if (key.includes(value)) {
          const combinationValues = preparedCombinations[key];

          const byPartOfBuildingRuValues = byPartOfBuildingValues[combinationValues.byPartOfBuildingListName]?.ru || [];
          const byPartOfBuildingEnValues = byPartOfBuildingValues[combinationValues.byPartOfBuildingListName]?.en || [];

          const byPartOfDrawingRuValues = byPartOfDrawingValues[combinationValues.byPartOfDrawingListName]?.ru || [];
          const byPartOfDrawingEnValues = byPartOfDrawingValues[combinationValues.byPartOfDrawingListName]?.en || [];

          const byNodeRuValues = byNodeValues[combinationValues.byNodeListName]?.ru || [];
          const byNodeEnValues = byNodeValues[combinationValues.byNodeListName]?.en || [];

          byPartOfBuildingResult = [...byPartOfBuildingResult, ...byPartOfBuildingRuValues, ...byPartOfBuildingEnValues];
          byPartOfDrawingResult = [...byPartOfDrawingResult, ...byPartOfDrawingRuValues, ...byPartOfDrawingEnValues];
          byNodeResult = [...byNodeResult, ...byNodeRuValues, ...byNodeEnValues]
        }
      }
    }
  });

  const byPartOfBuildingAllValues = byPartOfBuildingResult.map(({ value }) => value.trim());
  const byPartOfDrawingAllValues = byPartOfDrawingResult.map(({ value }) => value.trim());
  const byNodeAllValues = byNodeResult.map(({ value }) => value.trim());

  const byPartOfBuildingAllValuesSet = new Set(byPartOfBuildingAllValues);
  const byPartOfDrawingAllValuesSet = new Set(byPartOfDrawingAllValues);
  const byNodeAllValuesSet = new Set(byNodeAllValues);

  const sortedByPartOfBuildingValues = Array.from(byPartOfBuildingAllValuesSet).sort(sortByName).join('&&&');
  const sortedByPartOfDrawing = Array.from(byPartOfDrawingAllValuesSet).sort(sortByName).join('&&&');
  const sortedByNode = Array.from(byNodeAllValuesSet).sort(sortByName).join('&&&');

  return {
    byPartOfBuilding: changeStringToDataSelect(sortedByPartOfBuildingValues),
    byPartOfDrawing: changeStringToDataSelect(sortedByPartOfDrawing),
    byNode: changeStringToDataSelect(sortedByNode),
  }
}
