import React from "react";
import { useTranslation } from "react-i18next";

import { Page } from '../../hoc/Page';
import { ContentWrapper } from '../../hoc/ContentWrapper';
import { DownloadOffer } from "../../components/DownloadOffer/DownloadOffer";

export const Info = () => {
  const { t } = useTranslation();

  return (
    <Page>
      <ContentWrapper>
        <div className="d-flex align-items-center">
          {t('Info.Download')} <DownloadOffer text={t('Info.Offer')}/>
        </div>
      </ContentWrapper>
    </Page>
  )
}
