import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect } from "react";
import axios from "axios";

import {
  setAdminAlertMessage,
  setDrawings,
  setNewestDrawings,
  setAdminSearchDrawings,
} from "../store/slices/drawingsSlice";
import { setIsWaningModalOpen } from "../store/slices/warningModalSlice";
import { setCurrentPage, setFetching, setTotalCount, setToInitial } from "../store/slices/paginationSlice";
import { setUser } from "../store/slices/userSlice";
import {
  searchNewestDrawingsSelector,
  searchDrawingsParamsSelector,
  searchDrawingsSelector,
  searchAdminSearchDrawingsSelector,
  searchDrawingsIsSearchedSelector,
  paginationLimitSelector,
  paginationTotalCountSelector,
  paginationFetchingSelector,
  paginationCurrentPageSelector,
  isAdminSearchByNameSelector,
  adminFindStringSelector,
} from "../store/selectors";

import { API_URLS } from "../consts";

export const usePagination = (isNewestDrawings, isSearchedDrawings, isAdminDrawings) => {
  const dispatch = useDispatch();

  const newestDrawings = useSelector(searchNewestDrawingsSelector);
  const searchParams = useSelector(searchDrawingsParamsSelector);
  const searchDrawings = useSelector(searchDrawingsSelector);
  const isAdminSearchByName = useSelector(isAdminSearchByNameSelector);
  const adminFindString = useSelector(adminFindStringSelector);
  const adminDrawings = useSelector(searchAdminSearchDrawingsSelector);
  const isSearched = useSelector(searchDrawingsIsSearchedSelector);
  const limit = useSelector(paginationLimitSelector);
  const totalCount = useSelector(paginationTotalCountSelector);
  const fetching = useSelector(paginationFetchingSelector);
  const currentPage = useSelector(paginationCurrentPageSelector);

  const fetchNewest = () => {
    axios.get(`${API_URLS.drawings}/drawings/latest`, {
      params: {
        limit,
        page: currentPage,
      }
    }).then(({data}) => {
      dispatch(setCurrentPage({ currentPage: currentPage + 1 }));
      dispatch(setTotalCount({ totalCount: Number(data.totalCount) }));
      dispatch(
        setNewestDrawings({newestDrawings: [...newestDrawings, ...data.drawings]})
      );
    }).catch(() => {
      dispatch(setIsWaningModalOpen({ isOpen: true, variant: 'general'}));
    }).finally(() => {
      dispatch(setFetching({ fetching: false }));
    });
  };

  const fetchSearchedDrawing = () => {
    axios.get(`${API_URLS.drawings}/drawings/get-by-filter`, {
      params: {
        ...searchParams,
        page: currentPage,
        limit,
      },
    }).then(({ data }) => {
      dispatch(setCurrentPage({ currentPage: currentPage + 1 }));
      dispatch(setTotalCount({ totalCount: Number(data.totalCount) }));
      dispatch(setDrawings({ drawings: [ ...searchDrawings, ...data.drawings ] || [] }));
    }).catch(() => {
      dispatch(setIsWaningModalOpen({ isOpen: true, variant: 'general'}));
    }).finally(() => {
      dispatch(setFetching({ fetching: false }));
    });
  };

  const fetchAdminDrawing = () => {
    const token = localStorage.getItem('token');

    const URL = isAdminSearchByName ?
      `${API_URLS.admin}/drawings/get-by-name` :
      `${API_URLS.admin}/drawings/get-by-filter`;
    const params = isAdminSearchByName ?
      {
        name: adminFindString,
      } :
      {
        ...searchParams,
      };
    axios.get(URL, {
      params: {
        ...params,
        page: currentPage,
        limit,
        token,
      },
    }).then(({ data }) => {
      if (data.successfully) {
        if (data.drawings.length === 0) {
          dispatch(setAdminAlertMessage({ adminAlertMessage: 'Ничего не найдено. Попробуй снова' }));
        }

        dispatch(setCurrentPage({ currentPage: currentPage + 1 }));
        dispatch(setTotalCount({ totalCount: Number(data.totalCount) }));
        dispatch(setAdminSearchDrawings({ adminSearchDrawings: [ ...adminDrawings, ...data.drawings ] || [] }));
      } else {
        if (data.message === 'Errors.Auth.TokenExpired') {
          dispatch(setIsWaningModalOpen({ isOpen: true, variant: 'expired'}));
          dispatch(setUser({ user: null }));
          localStorage.removeItem('token');
        } else {
          dispatch(setAdminAlertMessage({ adminAlertMessage: 'Что-то пошло не так. Попробуй снова' }));
        }
      }
    }).catch(() => {
      dispatch(setIsWaningModalOpen({ isOpen: true, variant: 'general'}));
    }).finally(() => {
      dispatch(setFetching({ fetching: false }));
    });
  };

  useEffect(() => {
    if (!fetching) {
      return;
    }

    if (isNewestDrawings) {
      fetchNewest();
    }

    if (isSearchedDrawings && isSearched) {
      fetchSearchedDrawing();
    }

    if (isAdminDrawings && isSearched) {
      fetchAdminDrawing();
    }
  }, [fetching, isSearched]);

  const loadOnScroll = useCallback((event) => {
    let drawings = [];

    if (isNewestDrawings) {
      drawings = newestDrawings;
    }

    if (isSearchedDrawings) {
      drawings = searchDrawings;
    }

    if (isAdminDrawings) {
      drawings = adminDrawings;
    }

    if (isAdminDrawings && !isSearched) {
      return;
    }

    if (
      event.target.documentElement.scrollHeight - (event.target.documentElement.scrollTop + window.innerHeight) < 100
      && drawings.length < totalCount && !fetching
    ) {
      dispatch(setFetching({ fetching: true }));
    }
  }, [adminDrawings, dispatch, fetching, isAdminDrawings, isNewestDrawings, isSearched, isSearchedDrawings, newestDrawings, searchDrawings, totalCount]);

  useEffect(() => {
    document.addEventListener('scroll', loadOnScroll);

    return () => {
      document.removeEventListener('scroll', loadOnScroll);
    }
  }, [loadOnScroll]);

  useEffect(() => {
    return () => {
      dispatch(setToInitial());
    }
  }, [dispatch]);

  return {
    currentPage,
    fetching,
  }
}