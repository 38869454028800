import React from "react"
import { default as ReactSelect } from "react-select";

import { Option } from "./Option";

export const MultipleSelect = ({ options, onChange, selectedValue, classValue }) => (
  <span
    className={`d-inline-block mb-3 ${classValue && classValue}`}
    data-toggle="popover"
    data-trigger="focus"
    data-content="Выберите зависимость(ти)"
  >
    <ReactSelect
      options={options}
      isMulti
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      components={{
        Option
      }}
      onChange={onChange}
      allowSelectAll={true}
      value={selectedValue}
    />
  </span>
);
