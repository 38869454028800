import React, { useCallback, useState } from "react";
import { useDispatch } from 'react-redux';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import {
  setIsSearched,
  setIsAdminSearchByName,
  setAdminFindString,
  setAdminAlertMessage,
  setAdminSearchDrawings
} from '../../store/slices/drawingsSlice'
import { setToInitial } from "../../store/slices/paginationSlice";

export const AdminSearchByName = () => {
  const dispatch = useDispatch();
  const [currentValue, setCurrentValue] = useState('');

  const onFindClick = useCallback(() => {
    dispatch(setIsSearched({ isSearched: true }));
    dispatch(setIsAdminSearchByName({ isAdminSearchByName: true }));
    dispatch(setAdminFindString({ adminFindString: currentValue }));
    dispatch(setAdminAlertMessage({ adminAlertMessage: '' }));
    dispatch(setAdminSearchDrawings({ adminSearchDrawings: [] }));
    dispatch(setToInitial());
  }, [currentValue, dispatch]);

  const updateValue = useCallback((event) => {
    setCurrentValue(event.target.value);
  }, []);

  return (
    <div className="d-flex flex-column mb-5">
      <h3 className="mb-3">Найти по имени</h3>
      <Form className="d-flex w-100">
        <Form.Group className="w-75 d-flex flex-column" style={{ marginRight: '1rem' }}>
          <Form.Label>Введите название</Form.Label>
          <Form.Control
            type="text"
            placeholder="Введите название"
            value={currentValue}
            onChange={updateValue}
          />
        </Form.Group>
        <Form.Group className="w-25 justify-content-end d-flex" style={{ marginLeft: '1rem' }}>
          <Button onClick={onFindClick} className="w-100">
            Найти
          </Button>
        </Form.Group>
      </Form>
    </div>
  )
}
