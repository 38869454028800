import React from "react";

import Spinner from "react-bootstrap/Spinner";

import { DrawingCard } from '../DrawingCard/DrawingCard';

import './styles.css';

export const DrawingsList = (
  {
    drawings,
    isFavorites = false,
    isPurchases = false,
    isCart = false,
    isAdmin = false,
    voidMessage = '',
    isPartialLoading = false,
    onDeleteItemClick,
  }
) => {
  return (
    <>
      {drawings?.length !== 0 ? (
        <div className={isCart ? "drawingListWrapperInCart" : "drawingListWrapper"}>
          {drawings?.map(drawing => (
            <DrawingCard
              key={drawing.id}
              drawing={drawing}
              isFavorites={isFavorites}
              isCart={isCart}
              isPurchases={isPurchases}
              isAdmin={isAdmin}
              onDeleteItemClick={onDeleteItemClick}
            />
          ))}
          {isPartialLoading && <Spinner /> }
        </div>
      ) : (
        voidMessage ? <div className="drawingListWrapperVoid">{voidMessage}</div> : null
      )}
    </>
  )
}