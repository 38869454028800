import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import Spinner from "react-bootstrap/Spinner";

import { currencySelector, userIsCartLoading } from '../../store/selectors';

import { PayPal } from './PayPal/PayPal';
import { UKassa } from './UKassa/UKassa';

export const Payments = ({ totalCount, drawings, setCurrentItems }) => {
  const { t } = useTranslation();

  const currency = useSelector(currencySelector);
  const isCartLoading = useSelector(userIsCartLoading);

  return (
    <div className="d-flex flex-column">
      <h2 className="mb-5">
        {t('Cart.PaymentChooseTitle')}
      </h2>
      <h3 className="mb-4">
        {`${t('Cart.TotalCost')}: `}
        {isCartLoading ? (
          <Spinner />
        ) : (
          currency === 'rub' ?
            `${totalCount?.rub || 0} ₽` :
            `${totalCount?.usd || 0} $`
        )}
      </h3>
      {currency === 'rub' ?
        <UKassa drawings={drawings} totalCount={totalCount} setCurrentItems={setCurrentItems} /> :
        <PayPal drawings={drawings} totalCount={totalCount} setCurrentItems={setCurrentItems} />
      }
    </div>
  )
}
