import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  limit: 12,
  currentPage: 1,
  totalCount: 0,
  fetching: true,
};

const paginationSlice = createSlice({
  name: 'pagination',
  initialState,
  reducers: {
    setToInitial: (state) => {
      state.currentPage = 1;
      state.totalCount = 0;
      state.fetching = true;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload.currentPage;
    },
    setTotalCount: (state, action) => {
      state.totalCount = action.payload.totalCount;
    },
    setFetching: (state, action) => {
      state.fetching = action.payload.fetching;
    },
  },
});

export const { setToInitial, setTotalCount, setCurrentPage, setFetching } = paginationSlice.actions
export default paginationSlice.reducer
