import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";

import { Page } from '../../hoc/Page';
import { SearchForm } from "../../components/SearchForm/SearchForm";
import { ContentWrapper } from '../../hoc/ContentWrapper';
import { SearchDrawingsList } from '../../components/SearchDrawingsList/SearchDrawingsList';
import { NewestDrawings } from '../../components/NewestDrawings/NewestDrawings';
import { searchDrawingsIsSearchedSelector } from "../../store/selectors";
import { setIsWaningModalOpen } from "../../store/slices/warningModalSlice";
import { setIsSearched } from "../../store/slices/drawingsSlice";


import { API_URLS } from "../../consts";

import './styles.css';

export const Home = () => {
  const [filters, setFilters] = useState(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isSearched = useSelector(searchDrawingsIsSearchedSelector);

  const fetchFilters = useCallback(async () => {
    const token = localStorage.getItem('token');

    try {
      const { data } = await axios.get(`${API_URLS.root}/filters`, {
        params: {
          token,
        }
      });

      if (data.successfully) {
        setFilters(data.filters);
      }
    } catch (err) {
      dispatch(setIsWaningModalOpen({ isOpen: true, variant: 'general'}));
    }
  }, [dispatch]);

  useEffect(() => {
    fetchFilters();
  }, [fetchFilters]);

  useEffect(() => {
    return () => {
      dispatch(setIsSearched({ isSearched: false }));
    }
  }, []);

  return (
    <Page>
      <div className="wrapper d-flex flex-column" style={{paddingTop: '108px'}}>
        <div className='titleWrapper d-flex flex-column align-items-center'>
          <h1>{t('HomePage.Title')}</h1>
          <h3>{t('HomePage.Subtitle')}</h3>
        </div>
        <SearchForm filters={filters} />
      </div>
      <ContentWrapper topPadding={36}>
        <div className="d-flex align-items-center justify-content-center">
          {isSearched ? <SearchDrawingsList /> : <NewestDrawings />}
        </div>
      </ContentWrapper>
    </Page>
  )
}
