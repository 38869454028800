import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import axios from "axios";
import i18n from '../i18n';
import { useLocation, useNavigate } from 'react-router-dom';

import { Header } from '../components/Header';
import { AuthModal } from '../components/AuthForm/AuthModal';
import { WantToLoginModal } from '../components/WantToLoginModal/WantToLoginModal';
import { CartModal } from '../components/CartModal/CartModal';
import { WarningModal } from '../components/WarningModal/WarningModal';
import { OrderModal } from '../components/OrderModal/OrderModal';

import { setIsModalOpen } from '../store/slices/authModalSlice';
import { setUser, setIsUserLoading } from '../store/slices/userSlice';
import { setLocale } from '../store/slices/localeSlice';
import {
  setPurchases,
  setCart,
  setFavorites,
  setLocalCart
} from '../store/slices/staffSlice';
import { setCurrency, setIsUsdSelected } from '../store/slices/currencySlice';
import { setIsWaningModalOpen } from "../store/slices/warningModalSlice";
import {
  isAuthModalOpenSelector,
  userIsWantToLoginModalOpenSelector,
  userSelector,
  userIsCartModalOpen,
  isWarningModalOpenSelector,
  orderModalIsOpenSelector,
} from '../store/selectors';

import { API_URLS } from "../consts";

export const Page = ({children}) => {
  const isAuthModalOpen = useSelector(isAuthModalOpenSelector);
  const isOrderModalOpen = useSelector(orderModalIsOpenSelector);
  const isWantToLoginModalOpen = useSelector(userIsWantToLoginModalOpenSelector);
  const user = useSelector(userSelector);
  const isCartModalOpen = useSelector(userIsCartModalOpen);
  const isWarningModalOpen = useSelector(isWarningModalOpenSelector);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [currentCartItems, setCurrentCartItems] = useState([]);
  const [cartAlertMessage, setCartAlertMessage] = useState('');

  const onHide = useCallback(() => {
    dispatch(setIsModalOpen({ isOpen: false }));
  }, [dispatch]);

  const onOpen = useCallback(() => {
    dispatch(setIsModalOpen({ isOpen: true }));
  }, [dispatch]);

  useEffect(() => {
    const token = localStorage.getItem('token');

    async function fetchUser() {
      if (token) {
        try {
          const { data } = await axios.get(`${API_URLS.auth}/user`, {
            params: {
              token,
            }
          });

          if (data.message === 'User successfully signed in.') {
            dispatch(setUser({ user: data.user}));
          } else {
            dispatch(setIsWaningModalOpen({ isOpen: true, variant: 'expired'}));
            dispatch(setUser({ user: null }));
            localStorage.removeItem('token');
          }
        } catch (err) {
          dispatch(setIsWaningModalOpen({ isOpen: true, variant: 'general'}));
        } finally {
          dispatch(setIsUserLoading({ isUserLoading: false }));
        }
      } else {
        dispatch(setIsUserLoading({ isUserLoading: false }));
      }
    }

    fetchUser();
  }, [dispatch]);

  useEffect(() => {
    const locale = localStorage.getItem('locale') || i18n.resolvedLanguage;

    i18n.changeLanguage(locale);
    dispatch(setLocale({ locale }));
  }, [dispatch]);

  useEffect(() => {
    const currency = localStorage.getItem('currency');

    if (!currency) {
      dispatch(setCurrency({ currency: 'usd' }));
      dispatch(setIsUsdSelected({ isUsdSelected: true }));
    }

    dispatch(setCurrency({ currency }));

    if (currency === 'usd') {
      dispatch(setIsUsdSelected({ isUsdSelected: true }));
    } else {
      dispatch(setIsUsdSelected({ isUsdSelected: false }));
    }
  }, [dispatch]);

  useEffect(() => {
    const localCart = localStorage.getItem('localCart');

    if (localCart) {
      const localCartToSave = localCart.split(', ');

      dispatch(setLocalCart({ localCart: localCartToSave }));
    }
  }, [dispatch]);

  useEffect(() => {
    const fetchStaff = async (id) => {
      try {
        const { data } = await axios.get(`${API_URLS.staff}/${id}`);

        if (data.successfully) {
          const voidCart = data.staff?.cart?.split(', ').filter(cartId => cartId);
          const voidPurchases = data.staff?.purchases?.split(', ').filter(purchasesId => purchasesId);
          const voidFavorites = data.staff?.favorites?.split(', ').filter(favoritesId => favoritesId);

          dispatch(setCart({ cart: voidCart || [], isCartLoading: false }));
          dispatch(setPurchases({ purchases: voidPurchases || [], isPurchasesLoading: false }));
          dispatch(setFavorites({ favorites: voidFavorites || [], isFavoritesLoading: false }));
        }
      } catch (err) {
        console.log('err', err);
      }
    };

    if (user) {
      fetchStaff(user.userDrawingsId);
    }
  }, [dispatch, user]);

  useEffect(() => {
    const isRuLocation = pathname.includes('ru');
    const isEnLocation = pathname.includes('en');

    if (isRuLocation || isEnLocation) {
      localStorage.setItem('locale', isRuLocation ? 'ru' : 'en');
      navigate('/');
    }
  }, [navigate, pathname])

  return (
    <div style={{position: 'relative', width: '100%', top: 0}}>
      <Header
        onOpenAuthModal={onOpen}
        setCartAlertMessage={setCartAlertMessage}
        setCurrentCartItems={setCurrentCartItems}
      />
      <AuthModal show={isAuthModalOpen} onCloseModal={onHide} />
      <WantToLoginModal show={isWantToLoginModalOpen} />
      <CartModal
        show={isCartModalOpen}
        alertMessage={cartAlertMessage}
        setAlertMessage={setCartAlertMessage}
        currentItems={currentCartItems}
        setCurrentItems={setCurrentCartItems}
      />
      <OrderModal show={isOrderModalOpen} />
      <WarningModal show={isWarningModalOpen} />
      {children}
    </div>
  )
}
